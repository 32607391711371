import { request } from './shared';

export function searchInsurancePlansByPartDCode(partDCode, zipCode) {
  return request({
    method: 'GET',
    params: {
      part_d_code: partDCode,
      zip_code: zipCode,
    },
    silent: true,
    url: '/search-insurance-plans-by-partd-code',
  });
}
