import { request } from './shared';

export function multiSearchDrugInfo(countyCode, drugs, formulary_id, contract_id, plan_id, segment_id) {
  return request({
    data: {
      county_code: countyCode,
      drug_names: drugs,
      formulary_id: formulary_id,
      contract_id: contract_id, // added
      plan_id: plan_id,         // added
      segment_id: segment_id 
    },
    method: 'POST',
    url: '/multi-search-drug-info',
  });
}