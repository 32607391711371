import './index.css';
import { SearchOutlined, MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, Form, Select, message, Modal, Input, Space, Radio, Alert, DatePicker, Row, Col } from 'antd';
import React, { useState, useEffect } from 'react';
import { api, useAPI } from '../../api';
import M3PDrugClassResultsTable from '../../components/M3PResultsTable';
// import { debounce } from 'lodash';
// import ContactModal from '../../components/ContactModal';
import FormSuggestInput from '../../components/FormSuggestInput';
import StatusSelect from '../DrugClassSearchNew/StatusInput';

const SEARCH_FORM_FIELDS = {
    PartDCode: 'partDCode',
    SelectedPlan: 'selectedPlan',
    CountyCode: 'countyCode',
    Plan: 'plan',
    ZipCode: 'zipCode',
};

const SEARCH_METHOD = {
  BY_PARTD: 'byPartD',
  BY_COUNTY_INSURANCE: 'byCountyInsurance'
};

export default function MultiDrugSearch() {
    const [form] = Form.useForm();
    const m3pmultiSearchDrugInfoByPartDCode = useAPI(api.m3pmultiSearchDrugInfoByPartDCode);
    const searchInsurancePlansByPartDCode = useAPI(api.searchInsurancePlansByPartDCode, true);
    const m3pmultiSearchDrugInfo = useAPI(api.m3pmultiSearchDrugInfo);
    const sendContact = useAPI(api.sendContactInfo); 
    const [results, setResults] = useState([]);
    const [planOptions, setPlanOptions] = useState([]);
    const [, setSelectedPlan] = useState('');
    const [selectedPlanDetails, setSelectedPlanDetails] = useState({});
    const [expandedRow, setExpandedRow] = useState(null);
    const [deductible, setDeductible] = useState('');
    // const [isModalVisible, setIsModalVisible] = useState(false);
    const [isInstructionsModalVisible, setIsInstructionsModalVisible] = useState(false);
    const [isContactModalVisible, setIsContactModalVisible] = useState(false);

    const [searchMethod, setSearchMethod] = useState(SEARCH_METHOD.BY_PARTD);

    const getInsurancePlans = useAPI(api.getInsurancePlans);

    const [drugCosts, setDrugCosts] = useState([]); // New state for drug_costs
    // eslint-disable-next-line
    const [pharmacySummary, setPharmacySummary] = useState({}); 
    const getCountyFromZip = useAPI(api.getCountyFromZip);
    const [countyOptions, setCountyOptions] = useState([]);
    const [isCountySelectionVisible, setIsCountySelectionVisible] = useState(false);
    const [drugCostNote, setDrugCostNote] = useState("");

    const [paymentPlanStartMonth, setPaymentPlanStartMonth] = useState(0); 

    // Fetch counties from zip code
    const fetchCountiesFromZip = async (zipCode) => {
      try {
        const response = await getCountyFromZip(zipCode);
        if (response && response.counties) {
          return response.counties; // Return the counties list
        }
        message.error('No counties found for the provided ZIP code.');
        return [];
      } catch (error) {
        console.error('Error fetching counties:', error);
        message.error('An error occurred while fetching counties.');
        return [];
      }
    };

    // Handle county selection and update state
    const handleCountySelection = (counties) => {
      if (counties.length > 1) {
        setCountyOptions(
          counties.map(county => ({
            label: county.county_name,
            value: county.county_code,
          }))
        );
        setIsCountySelectionVisible(true); // Show dropdown if multiple counties
      } else if (counties.length === 1) {
        form.setFieldValue(SEARCH_FORM_FIELDS.CountyCode, counties[0].county_code);
        setCountyOptions([]); // Clear options since it's auto-selected
        setIsCountySelectionVisible(false); // Hide dropdown
        planOptionsDepsChangeCallback(); // Trigger plan fetch
      } else {
        setCountyOptions([]);
        setIsCountySelectionVisible(false);
      }
    };


    async function planOptionsDepsChangeCallback() {
      const zipCode = form.getFieldValue(SEARCH_FORM_FIELDS.ZipCode);
      console.log('Zip Code from form:', zipCode);
  
      if (!zipCode || zipCode.length !== 5) {
        setPlanOptions([]);
        message.error('Please enter a valid 5-digit zip code.');
        return;
      }
  
      try {
        // Fetch counties by zip code
        const countyResponse = await getCountyFromZip(zipCode);
        if (!countyResponse || !Array.isArray(countyResponse.counties) || countyResponse.counties.length === 0) {
          message.error('No counties found for the provided zip code.');
          return;
        }
    
        const counties = countyResponse.counties;
    
        if (counties.length === 1) {
          // If only one county is found, automatically use it
          const countyCode = counties[0].county_code;
          form.setFieldValue(SEARCH_FORM_FIELDS.CountyCode, countyCode);
          await fetchPlanOptions(countyCode); // Fetch plans based on the single county
        } else {
          // If multiple counties, show a dropdown for user selection
          setCountyOptions(
            counties.map(county => ({
              label: county.county_name,
              value: county.county_code,
            }))
          );
          setIsCountySelectionVisible(true); // Display the county selection dropdown
        }
      } catch (ex) {
        console.error('Error fetching counties by zip code:', ex);
        message.error('Error fetching counties: ' + (ex.message || ex));
      }
    }
  
    async function fetchPlanOptions(countyCode) {

      const includeMA = true;
      const includePDP = true;
      
      if (!(includeMA || includePDP)) {
        setPlanOptions([]); // Reset plan options if no plan types are selected
        return;
      }
    
      try {
        const response = await getInsurancePlans(countyCode, includeMA, includePDP);
        if (response && Array.isArray(response.plans)) {
          const uniquePlans = new Map();
          response.plans.forEach(plan => {
            console.log(plan);
            const compositeKey = `${plan.contract_id}-${plan.plan_id}-${plan.segment_id}`;
            uniquePlans.set(compositeKey, plan);
          });
    
          const sortedPlanOptions = Array.from(uniquePlans.values())
            .sort((a, b) => a.plan_name.localeCompare(b.plan_name))
            .map(plan => ({
              label: `${plan.plan_name} (Formulary: ${plan.formulary_id}) - ${plan.contract_name}`,
              value: `${plan.contract_id}-${plan.plan_id}-${plan.segment_id}`, // Ensure this composite key is unique
              planDetails: {
                formulary_id: plan.formulary_id,
                contract_id: plan.contract_id,
                plan_id: plan.plan_id,
                segment_id: plan.segment_id,
                deductible: plan.deductible,
                plan_name: plan.plan_name
              }
            }));
          setPlanOptions(sortedPlanOptions);
        }
      } catch (ex) {
        console.error('Error fetching insurance plans:', ex);
        message.error('Error fetching insurance plans: ' + (ex.message || ex));
      }
    }
    
    useEffect(() => {
      const plan = form.getFieldValue(SEARCH_FORM_FIELDS.Plan);
      if (plan && planOptions.find(option => option.value === plan) === undefined) {
        form.setFieldValue(SEARCH_FORM_FIELDS.Plan, '');
      }
    }, [planOptions, form]);

    const handleFormSubmit = async (values) => {
      const drugs = form.getFieldValue('drugs');
      const zipCode = form.getFieldValue(SEARCH_FORM_FIELDS.ZipCode); 

      if (searchMethod === SEARCH_METHOD.BY_PARTD) {

        const formulary_id = selectedPlanDetails.formulary_id || '';
        const contract_id = selectedPlanDetails.contract_id || ''; 
        const plan_id = selectedPlanDetails.plan_id || '';         
        const segment_id = selectedPlanDetails.segment_id || '';   
        
        console.log(selectedPlanDetails); 

        if (!formulary_id) {
          console.error('Formulary ID is missing.');
          message.error('Please select a plan before searching.');
          return;
        }

        if (!zipCode) {
          message.error('Zip Code is required.');
          return;
        }

        try {
          const data = await m3pmultiSearchDrugInfoByPartDCode(drugs, formulary_id, contract_id, plan_id, segment_id, zipCode);
          const updatedResults = data.drug_results.map(drug => ({ ...drug }));
          const drugCosts = data.drug_costs || []; // Default to an empty array if not present
          const pharmacySummary = data.pharmacy_summary || {}; // Default to an empty object if not present
          const drugCostNote = data.drug_cost_note || "";
      
          setResults(updatedResults);
          setSelectedPlan(formulary_id);
          setDrugCosts(drugCosts); // Add a new state for drug_costs
          setPharmacySummary(pharmacySummary); // Add a new state for pharmacy_summary
          setDrugCostNote(drugCostNote);
          setPaymentPlanStartMonth(values.paymentPlanStartMonth);
        } catch (error) {
          console.error('Error fetching drug class info by PartD Code:', error);
          message.error('An error occurred while fetching the drug class info.');
        }
      } else if (searchMethod === SEARCH_METHOD.BY_COUNTY_INSURANCE) {
        const countyCode = form.getFieldValue(SEARCH_FORM_FIELDS.CountyCode);
        const {
          formulary_id,
          contract_id,
          plan_id,
          segment_id
        } = selectedPlanDetails;
        
        console.log('newselectedPlanDetails:', selectedPlanDetails); 
    
        if (!countyCode) {
          console.error('County Code is missing.');
          message.error('Please enter a county name.');
          return;
        }
    
        if (!formulary_id) {
          console.error('Formulary ID is missing.');
          message.error('Please select an insurance plan.');
          return;
        }
    
        try {
          const data = await m3pmultiSearchDrugInfo(countyCode, drugs, formulary_id, contract_id, plan_id, segment_id);
          const updatedResults = data.drug_results.map(drug => ({ ...drug }));
          const drugCosts = data.drug_costs || []; // Default to an empty array if not present
          const pharmacySummary = data.pharmacy_summary || {}; // Default to an empty object if not present
          const drugCostNote = data.drug_cost_note || "";
          
          setResults(updatedResults);
          setSelectedPlan(formulary_id);
          setDrugCosts(drugCosts);
          setPharmacySummary(pharmacySummary);
          setDrugCostNote(drugCostNote);
          setPaymentPlanStartMonth(values.paymentPlanStartMonth);
        } catch (error) {
          console.error('Error fetching drug class info by County and Insurance:', error);
          message.error('An error occurred while fetching the drug class info.');
        }
      }
    };
    
      
    const fetchPlansBasedOnPartDCode = async (partDCode) => {
      // Get the ZIP code from the form
      const zipCode = form.getFieldValue(SEARCH_FORM_FIELDS.ZipCode);
      console.log("Using ZIP Code:", zipCode);

      if (!partDCode || (partDCode.length !== 8 && partDCode.length !== 11)) {
          setPlanOptions([]);
          const error = new Error('PartD/Plan Benefits Code must be 8 or 11 characters long.');
          error.help = 'PartD/Plan Benefits Code must be 8 or 11 characters long.';
          throw error;
      }

      if (!zipCode || zipCode.length !== 5) {
        setPlanOptions([]);
        message.error('Please enter a valid 5-digit zip code.');
        return;
      }

      try {
        const response = await searchInsurancePlansByPartDCode(partDCode, zipCode);
        if (response && response.formulary_id) { // Check for the existence of formulary_id or another unique field
          // Directly use the response object since it's not an array of plans
          setPlanOptions([{
            label: `${partDCode}: ${response.plan_name}`,
            value: response.formulary_id,
            planDetails: response // Storing the entire plan object for later use
          }]);
        } else {
          setPlanOptions([]);
        }
        return response;
      } catch (error) {
        console.error('Error:', error);
        const throwErr = new Error('No plan details found, please re-check your input.');
        throwErr.help = 'No plan details found, please re-check your input.';
        throw throwErr;
      }
    };
  const debouncedSearchPlans = fetchPlansBasedOnPartDCode;//debounce(fetchPlansBasedOnPartDCode, 800);


  useEffect(() => {
    const partDCode = form.getFieldValue(SEARCH_FORM_FIELDS.PartDCode);
    // Only call debouncedSearchPlans if partDCode has a valid length
    if (partDCode && (partDCode.length === 8 || partDCode.length === 11)) {
      debouncedSearchPlans(partDCode);
    }
  }, [form, debouncedSearchPlans]);

  const handlePlanChange = (value) => {
    const selectedOption = planOptions.find(option => option.value === value);
    if (selectedOption && selectedOption.planDetails) {
      setSelectedPlanDetails(selectedOption.planDetails);
      setDeductible(selectedOption.planDetails.deductible);
      // Update form fields based on the current search method
      if (searchMethod === SEARCH_METHOD.BY_PARTD) {
        form.setFieldsValue({ [SEARCH_FORM_FIELDS.PartDCode]: selectedOption.planDetails.formulary_id });
      } else if (searchMethod === SEARCH_METHOD.BY_COUNTY_INSURANCE) {
        form.setFieldsValue({ [SEARCH_FORM_FIELDS.Plan]: selectedOption.value });
      }
  } else {
      console.error('Selected plan details not found.');
      setSelectedPlanDetails({});
      setDeductible(undefined);
      // Reset the PartDCode in the form state
      form.setFieldsValue({ [SEARCH_FORM_FIELDS.PartDCode]: '' });
      alert('Plan details are incomplete or missing.');
  }
};
  

  const handleInstructionsModalCancel = () => {
    setIsInstructionsModalVisible(false);
  };

  const handleContactModalSubmitPartD = async (values) => {
    try {
      const response = await sendContact(values); 

      if (response && response.message) {
        message.success(response.message || 'Your request has been submitted. We will contact you shortly.', 3);  // Show success message with a green checkmark
        setIsContactModalVisible(false);
      } else {
        // Handle server errors
        message.error(response.message || 'An error occurred. Please try again later.');
      }
    } catch (error) {
      // Handle network errors
      console.error('There was an error submitting the form:', error);
      message.error('There was a network error. Please try again later.');
    }
  };

  const handleContactModalCancel = () => {
    setIsContactModalVisible(false);
  };

  const showContactModal = () => {
    setIsInstructionsModalVisible(false);
    setIsContactModalVisible(true);
  };

  const handleSearchMethodChange = (e) => {
    setSearchMethod(e.target.value);
    form.resetFields();
  };
    
      return (
        <>
          <Button
            onClick={showContactModal}
            className="floating-help-button"
            style={{
              marginBottom: 16,
              backgroundColor: '#f5f5f5', // This is a light gray color similar to the table header
              color: 'black', // The text color is black
              borderColor: '#f5f5f5' // The border color is the same as the background
            }}
          >
            <strong>Email Us</strong>
          </Button>

          {/* Title with Beta Tag */}
          <div style={{ marginBottom: 16, display: 'flex', alignItems: 'center' }}>
            <h1 style={{
              margin: 0,
              fontSize: '24px',
              fontWeight: 'bold',
              color: '#333'
            }}>
              Medicare Prescription Payment Plan Tool
            </h1>
            <span style={{
              backgroundColor: '#007bff',
              color: '#fff',
              fontSize: '12px',
              fontWeight: 'bold',
              borderRadius: '4px',
              padding: '2px 6px',
              marginLeft: '8px'
            }}>
              Beta
            </span>
          </div>

          <Radio.Group onChange={handleSearchMethodChange} value={searchMethod} style={{ marginBottom: 16 }}>
            <Radio.Button value={SEARCH_METHOD.BY_PARTD}>Search by Plan ID</Radio.Button>
            <Radio.Button value={SEARCH_METHOD.BY_COUNTY_INSURANCE}>Search by Insurance Name</Radio.Button>
          </Radio.Group>

          <Form 
            form={form} 
            layout="vertical" 
            onFinish={handleFormSubmit} 
            style={{ maxWidth: 1200 }}
            className="custom-form" 
            initialValues={{
                drugs: [''], 
              }}
           >
            {searchMethod === SEARCH_METHOD.BY_PARTD && (
            <>
              <Form.Item
                label="Zip Code"
                name={SEARCH_FORM_FIELDS.ZipCode} // Use the updated field name
                rules={[
                  { required: true, message: 'Please enter a zip code.' },
                  { pattern: /^\d{5}$/, message: 'Please enter a valid 5-digit zip code.' },
                ]}
              >
                <Input placeholder="" />
              </Form.Item>
              <Form.Item
              name={SEARCH_FORM_FIELDS.PartDCode}
              label={
                <span>
                  Medicare Plan ID{" "}
                  <button 
                    type="button" 
                    onClick={() => setIsInstructionsModalVisible(true)} 
                    className="link-button" 
                    style={{ 
                      padding: 0, 
                      backgroundColor: 'transparent', 
                      border: 'none', 
                      color: '#007bff', 
                      textDecoration: 'underline', 
                      cursor: 'pointer', // Make it clear that this is clickable
                    }}
                    onMouseEnter={(e) => e.target.style.textDecoration = 'none'}  // Remove underline on hover
                    onMouseLeave={(e) => e.target.style.textDecoration = 'underline'}  // Restore underline on mouse out
                  >
                    (Example)
                  </button>
                </span>
              }
              rules={[{ required: true, message: 'Please enter Medicare Plan ID.' }]}
            >
            <StatusSelect
            options = {planOptions}
            onSearch={debouncedSearchPlans}
            onChange={handlePlanChange}
            showContactModal={showContactModal}
            autoFill
            />
          </Form.Item>
            <Modal
              title="How to Find Medicare Plan ID"
              visible={isInstructionsModalVisible}
              onCancel={handleInstructionsModalCancel}
              footer={[
                <Button key="back" onClick={handleInstructionsModalCancel}>
                  Close
                </Button>,
              ]}
            >
              <ul>
                <li>Check insurance card for a code starts with a letter (H, R, or S) followed by a series of 7 or 10 digits.</li>
                <li>Example codes might look like <strong>H6158-001</strong> or <strong>H6158-001-002</strong>.</li>
                <li>If you cannot find the code, <button type="button" onClick={showContactModal} className="link-button" style={{ padding: 0, backgroundColor: 'transparent', border: 'none', color: '#007bff', textDecoration: 'underline' }}>contact us</button> and we will help you find it</li>
                <img src="https://i.imgur.com/ovIpEVp.jpeg" alt="Example PartD Code" style={{ maxWidth: '100%', marginTop: '20px' }} />
              </ul>
            </Modal>
            <Modal
              title="Contact Us for Help"
              visible={isContactModalVisible}
              onCancel={handleContactModalCancel}
              footer={null}
            >
              <Form onFinish={handleContactModalSubmitPartD}>
                <Form.Item
                  name="name"
                  rules={[{ required: true, message: 'Please input your name!' }]}
                >
                  <Input placeholder="Your Name" />
                </Form.Item>
                <Form.Item
                  name="email"
                  rules={[{ required: true, message: 'Please input your email!', type: 'email' }]}
                >
                  <Input placeholder="Your Email" />
                </Form.Item>
                <Form.Item
                  name="message"
                  rules={[{ required: true, message: 'Please provide insurance details or patient info.' }]}
                >
                  <Input.TextArea 
                    placeholder="Provide any information you have about the patient's insurance, or provide the patient's name and date of birth and we will assist you in finding it."
                    rows={4}
                  />
                </Form.Item>
                <Form.Item>
                  <Button type="primary" htmlType="submit">
                    Submit
                  </Button>
                </Form.Item>
              </Form>
            </Modal>
            </>
          )}
          {searchMethod === SEARCH_METHOD.BY_COUNTY_INSURANCE && (
            <>
              <Form.Item
                name={SEARCH_FORM_FIELDS.ZipCode}
                label="Zip Code"
                rules={[
                  { required: true, message: 'Please enter a zip code.' },
                  { pattern: /^\d{5}$/, message: 'Please enter a valid 5-digit zip code.' },
                ]}
              >
                <Input
                  onChange={async (e) => {
                    const zipCode = e.target.value.trim();
                    if (zipCode.match(/^\d{5}$/)) {
                      const counties = await fetchCountiesFromZip(zipCode);
                      handleCountySelection(counties); // Update county options
                    } else {
                      // Reset state on invalid zip code
                      setCountyOptions([]);
                      setIsCountySelectionVisible(false);
                      form.setFieldsValue({ [SEARCH_FORM_FIELDS.CountyCode]: null });
                      setPlanOptions([]);
                    }
                  }}
                />
              </Form.Item>

              {/* Dynamically render the county dropdown if multiple counties are found */}
              {isCountySelectionVisible && (
                <Form.Item
                  name={SEARCH_FORM_FIELDS.CountyCode}
                  label="Select County"
                  rules={[{ required: true, message: 'Please select a county.' }]}
                >
                  <Select
                    showSearch
                    optionFilterProp="children"
                    onChange={async (value) => {
                      form.setFieldsValue({ [SEARCH_FORM_FIELDS.CountyCode]: value });
                      await fetchPlanOptions(value); // Trigger plan options update based on selected county
                    }}
                  >
                    {countyOptions.map((county) => (
                      <Select.Option key={county.value} value={county.value}>
                        {county.label}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              )}
              <Form.Item
                name={SEARCH_FORM_FIELDS.Plan}
                label="Insurance Name"
                rules={[{ required: true, message: 'Please select an insurance plan.' }]}
              >
                <Select
                  showSearch
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                  onChange={handlePlanChange}
                >
                  {planOptions.map(option => (
                    <Select.Option key={option.value} value={option.value}>
                      {option.label}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </>
          )}


            <Form.List
                name="drugs"
                rules={[
                  {
                    message: 'Drug names are duplicate.',
                    validator(_, value) {
                      const matches = value.filter(Boolean);
                      if (matches.length !== new Set(matches).size) {
                        return Promise.reject();
                      }
                      return Promise.resolve();
                    },
                  },
                ]}
              >
                {(fields, { add, remove }, { errors }) => (
                  <>
                    {fields.map((field) => (
                      <div
                        key={field.key}
                        style={{
                          marginBottom: '10px',
                          padding: '10px',
                          border: '1px solid #ccc',
                          borderRadius: '4px',
                        }}
                      >
                        <Row gutter={16} align="top">
                          {/* Widen the Drug Name column */}
                          <Col span={10}>
                            <Form.Item
                              {...field}
                              label={
                                <Space size="middle">
                                  Drug Name
                                  <span
                                    style={{ fontSize: 12, cursor: 'pointer', color: '#8c8c8c' }}
                                    onClick={() => remove(field.name)}
                                  >
                                    <MinusCircleOutlined style={{ cursor: 'pointer' }} /> Remove this Drug
                                  </span>
                                </Space>
                              }
                              name={[field.name, 'drug']}
                              rules={[{ required: true, message: 'Please enter a drug name.' }]}
                            >
                              <FormSuggestInput field="drugm3p" className="form-item-custom" />
                            </Form.Item>
                          </Col>

                          {/* Keep Frequency the same */}
                          <Col span={6}>
                            <Form.Item
                              {...field}
                              label="Frequency"
                              name={[field.name, 'frequency']}
                              rules={[{ required: true, message: 'Please select frequency.' }]}
                            >
                              <Select placeholder="Select frequency">
                                <Select.Option value="30">30 Days</Select.Option>
                                {/* <Select.Option value="60">60 Days</Select.Option> */}
                                <Select.Option value="90">90 Days</Select.Option>
                              </Select>
                            </Form.Item>
                          </Col>

                          {/* Keep Time Prescribed the same */}
                          <Col span={6}>
                            <Form.Item
                              {...field}
                              label="Time Prescribed"
                              name={[field.name, 'prescribedDate']}
                              rules={[{ required: true, message: 'Please select the prescribed date.' }]}
                            >
                              <DatePicker style={{ width: '100%' }} />
                            </Form.Item>
                          </Col>
                        </Row>
                      </div>
                    ))}


                    <Form.Item>
                        <Form.Item className="form-item-custom">
                        <Button type="dashed" block onClick={() => add('')} icon={<PlusOutlined />}>
                            Add Another Drug
                        </Button>
                        </Form.Item>
                        <Form.ErrorList errors={errors} />
                    </Form.Item>
                    </>
                )}
                </Form.List>

                <Form.Item
                  label="Payment Plan Start Month"
                  name="paymentPlanStartMonth"
                  rules={[{ required: true, message: 'Please select a starting month for the payment plan.' }]}
                  initialValue={0} // default to January
                >
                  <Select
                    onChange={(value) => {
                      console.log("Selected Payment Plan Start Month:", value);
                      setPaymentPlanStartMonth(value);
                    }}
                    placeholder="Select start month"
                  >
                    <Select.Option value={0}>January</Select.Option>
                    <Select.Option value={1}>February</Select.Option>
                    <Select.Option value={2}>March</Select.Option>
                    <Select.Option value={3}>April</Select.Option>
                    <Select.Option value={4}>May</Select.Option>
                    <Select.Option value={5}>June</Select.Option>
                    <Select.Option value={6}>July</Select.Option>
                    <Select.Option value={7}>August</Select.Option>
                    <Select.Option value={8}>September</Select.Option>
                    <Select.Option value={9}>October</Select.Option>
                    <Select.Option value={10}>November</Select.Option>
                    <Select.Option value={11}>December</Select.Option>
                  </Select>
                </Form.Item>

                <Form.Item>
                <Button type="primary" htmlType="submit" icon={<SearchOutlined />}>
                    Search
                </Button>
            </Form.Item>
            </Form>
          
            {selectedPlanDetails && (
            <div>
            <p><strong>Searched Plan Name: {selectedPlanDetails.plan_name}</strong></p>
            
            {searchMethod === SEARCH_METHOD.BY_COUNTY_INSURANCE &&
            selectedPlanDetails.contract_id &&
            selectedPlanDetails.plan_id !== undefined &&
            selectedPlanDetails.segment_id !== undefined && (
              <p>
                <strong>Plan ID:</strong>{" "}
                <span style={{ fontWeight: "bold", color: "black" }}>
                  {selectedPlanDetails.contract_id}-
                  {String(selectedPlanDetails.plan_id).padStart(3, "0")}-
                  {selectedPlanDetails.segment_id}
                </span>{" "}
                <span style={{ color: "black", size: 'small' }}>
                  (Please save this Plan ID for your next search.)
                </span>
              </p>
            )}

            {pharmacySummary && (pharmacySummary.Preferred?.length > 0 || pharmacySummary.In_network?.length > 0) ? (
              <div>
                <strong>
                  {pharmacySummary.Preferred?.length > 0
                    ? "Preferred Pharmacies:"
                    : "In-Network Pharmacies:"}
                </strong>{" "}
                {pharmacySummary.Preferred?.length > 0
                  ? pharmacySummary.Preferred.map(pharmacy => pharmacy.replace(/PHARMACY/i, "").trim()).join(", ")
                  : pharmacySummary.In_network?.map(pharmacy => pharmacy.replace(/PHARMACY/i, "").trim()).join(", ")}
              </div>
            ) : (
              <div>
                <strong>Pharmacy Information:</strong>
              </div>
            )}
            <p><div><strong>Plan Annual Deductible:</strong> ${typeof deductible === 'number' ? deductible.toFixed(2) : ''}</div></p>
          </div>
          )}

          {drugCostNote && (
            <Alert
              message={<span style={{ fontWeight: 'bold' }}>Retail Cost Not Available</span>}
              description={drugCostNote}
              type="warning"
              showIcon
              style={{
                margin: '10px 0', // Smaller margin above and below
                padding: '5px', // Compact padding
                fontSize: '14px', // Smaller text size
                lineHeight: '1.5', // Adjust line height for better readability
              }}
            />
          )}


          <M3PDrugClassResultsTable
            pagination={{
              pageSize: results.length, // Set page size to the length of results to show all
            }}
            results={results}
            selectedInsurancePlan={selectedPlanDetails.formulary_id}
            selectedPlanDetails={selectedPlanDetails}
            drugCosts={drugCosts}
            expandedRow={expandedRow}
            setExpandedRow={setExpandedRow}
            handlePopupOpen
            paymentPlanStartMonth={paymentPlanStartMonth}
          />
        </>
      );
    }