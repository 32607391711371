import React from 'react';
import ReactDOM from 'react-dom/client';
import { Link } from 'react-router-dom';
// import { Tag } from 'antd';
// eslint-disable-next-line 
import { SearchOutlined, MedicineBoxOutlined, UserOutlined, HeartOutlined, ExperimentOutlined, LineChartOutlined } from '@ant-design/icons';
// import { DiabetesDrugIcon, CardiologyDrugIcon, InhalerIcon } from './components/icon';
import App from './pages/App';
// import DrugClassSearchPage from './pages/DrugClassSearch';
import DrugClassSearchNewPage from './pages/DrugClassSearchNew';
// import LoginPage from './pages/Login';
import MultiDrugSearchPage from './pages/MultiDrugSearch';
import PAPPortalPage from './pages/PAPPortal';
import PatientAssistanceProgramPage from './pages/PatientAssistanceProgram';
import PriorAuthorizationPage from './pages/PriorAuthorization';
import RegisterPage from './pages/Register';
import SingleDrugSearchPage from './pages/SingleDrugSearch';
import UtahPage from './pages/UtahSeachPage';
import CardiologyDrugClassSearchNewPage from './pages/CardiologyDrugClassSearchNew';
import AsthmaCOPDDrugClassSearchPage from './pages/AsthmaDrugClassSearch';
import M3PPage from './pages/M3P';
import ErrorBoundary from './components/ErrorBoundary'; 
import './index.css';

ReactDOM.createRoot(document.getElementById('root')).render(
  <React.StrictMode>
    <ErrorBoundary>
      <App
        title={
          // setup application title
          <Link to="/">Medicare Medication Coverage Check</Link>
        }
        headers={[
          // configure navigation links on the top-right
          <Link to="/login"><UserOutlined /> Login</Link>,
        ]}
        routes={[
          // configure routes to the application.
          // items that contain both `menuLabel` and `menuIcon`
          // will be presented on the sidebar
          // {
          //   path: '/drug-class-search-new',
          //   element: <DrugClassSearchPage />,
          //   menuLabel: 'Drug Class Search',
          //   menuIcon: <SearchOutlined />,
          // },
          
          {
            path: '/',
            element: <DrugClassSearchNewPage />,
            menuLabel: 'Diabetes Drug Class',
            menuIcon: <MedicineBoxOutlined />,
          },
          {
            path: '/cardiology-drug-search',
            element: <CardiologyDrugClassSearchNewPage />,
            menuLabel: 'Cardiology Drug Class',
            menuIcon: <HeartOutlined />,
          },
          {
            path: '/asthmacopd-drug-search',
            element: <AsthmaCOPDDrugClassSearchPage />,
            menuLabel: 'Inhaler Asthma/COPD ',
            menuIcon: <ExperimentOutlined />,
          },
          {
            path: '/multi-drug-search',
            element: <MultiDrugSearchPage />,
            menuLabel: 'Drug Name Search',
            menuIcon: <SearchOutlined />,
          },
          {
            path: '/drug-class-search',
            element: <SingleDrugSearchPage />,
            // menuLabel: 'Single Drug Search',
            // menuIcon: <SearchOutlined />,
          },
          {
            path: '/prior-authorization',
            element: <PriorAuthorizationPage />,
          },
          {
            path: '/patient-assistance-program',
            element: <PatientAssistanceProgramPage />
          },
          {
            path: '/pap-portal',
            element: <PAPPortalPage />,
          },
          // {
          //   path: '/login',
          //   element: <LoginPage />,
          // },
          {
            path: '/register',
            element: <RegisterPage />,
          },
        {
          path: '/utah',
          element: <UtahPage />,
          menuLabel: 'Utah Plan Search',
          menuIcon: <SearchOutlined />,
          },
          {
            path: '/M3P',
            element: <M3PPage />,
            // menuLabel: (
            //   <>
            //     M3P <Tag color="blue">Beta</Tag>
            //   </>
            // ),
            // menuIcon: <LineChartOutlined />,
            },
        ]}
      />
    </ErrorBoundary>
  </React.StrictMode>
);
