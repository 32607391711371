import './index.css';
import { QuestionCircleOutlined } from '@ant-design/icons';
import { Modal, Table, Button, Select, Tooltip as AntdTooltip } from 'antd'; 
import React, { useState, useEffect } from 'react';
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  Tooltip as RechartsTooltip,
  CartesianGrid,
  Legend,
  ReferenceLine,
} from 'recharts';

const monthAbbrevMap = {
  January: 'Jan',
  February: 'Feb',
  March: 'Mar',
  April: 'Apr',
  May: 'May',
  June: 'Jun',
  July: 'Jul',
  August: 'Aug',
  September: 'Sep',
  October: 'Oct',
  November: 'Nov',
  December: 'Dec',
};

/**
 * PaymentPlanChart
 * @param {Object[]} data - e.g. [ { month: "January", outOfPocket: 400, m3pBill: 200 }, ... ]
 * @param {number} paymentPlanStartMonth - 0-based index (0=Jan, 1=Feb, etc.) for ReferenceLine
 */
function PaymentPlanChart({ data, paymentPlanStartMonth }) {
  // Convert full month names to abbreviations and rename fields for Recharts
  const chartData = data
    .filter((row) => row.month !== 'Annual Total') // exclude "Annual Total" row if present
    .map((row) => ({
      monthAbbrev: monthAbbrevMap[row.month] || row.month,
      outOfPocket: row.outOfPocket || 0,
      m3pBill: row.m3pBill || 0,
    }));

  // Compute the X value for the reference line if it's in [0..chartData.length-1]
  const refLineX =
    paymentPlanStartMonth >= 0 && paymentPlanStartMonth < chartData.length
      ? chartData[paymentPlanStartMonth].monthAbbrev
      : null;

  // Custom tooltip formatter to display the cost with a dollar sign
  const tooltipFormatter = (value, name) => {
    if (typeof value === 'number') {
      const costStr = `$${value.toFixed(2)}`;
      if (name === 'Out-of-Pocket') {
        return [costStr, 'Out-of-Pocket (w/o M3P)'];
      } else if (name === 'M3P Bill') {
        return [costStr, 'M3P Bill'];
      }
    }
    return value;
  };

  return (
    <div style={{ marginTop: '2rem', textAlign: 'center' }}>
      <h3 style={{ marginBottom: 20 }}>Monthly Payment Trend</h3>

      <LineChart
        width={1200}
        height={600}
        data={chartData}
        margin={{ top: 30, right: 30, left: 20, bottom: 40 }}
      >
        {/* Light grid lines */}
        <CartesianGrid strokeDasharray="3 3" stroke="#ccc" strokeOpacity={0.7} />

        {/* X-axis with label */}
        <XAxis
          dataKey="monthAbbrev"
          label={{
            value: 'Month',
            position: 'insideBottom',
            offset: -15,
            fontSize: 16,
          }}
          tick={{ fontSize: 14 }}
        />

        {/* Y-axis with label */}
        <YAxis
          label={{
            value: 'Cost ($)',
            angle: -90,
            position: 'insideLeft',
            offset: 0,
            fontSize: 16,
          }}
          tick={{ fontSize: 14 }}
        />

        {/* Hover tooltip */}
        <RechartsTooltip
          formatter={tooltipFormatter}
          contentStyle={{ fontSize: 14 }}
        />

        {/* Legend with bigger icons & font */}
        <Legend
          verticalAlign="top"
          align="center"
          iconSize={20}
          wrapperStyle={{ fontSize: 16, marginBottom: 20 }}
        />

        {/* Out-of-Pocket line (straight lines) */}
        <Line
          type="linear" // ensures straight lines
          dataKey="outOfPocket"
          name="Out-of-Pocket (w/o M3P)"
          stroke="#2f4b7c"
          strokeWidth={3}
          dot={{ r: 6 }}
          activeDot={{ r: 8 }}
        />

        {/* M3P Bill line (straight lines) */}
        <Line
          type="linear"
          dataKey="m3pBill"
          name="M3P Bill"
          stroke="#ffa600"
          strokeWidth={3}
          dot={{ r: 6 }}
          activeDot={{ r: 8 }}
        />

        {/* Red dashed line for Payment Plan start, if valid */}
        {refLineX && (
          <ReferenceLine
            x={refLineX}
            stroke="red"
            strokeDasharray="5 5"
            label={{
              value: 'Payment Plan Start',
              position: 'insideTop',
              fill: 'red',
              fontSize: 14,
              fontWeight: 'bold',
            }}
          />
        )}
      </LineChart>
    </div>
  );
}
// import { Link } from 'react-router-dom';
// import { api, useAPI } from '../../api';

export function renderRequirements(drug, selectedInsurancePlan, handlePopupOpen, sendEmailNotification) {
  const requirements = Array.isArray(drug.requirements) ? drug.requirements : [drug.requirements];
  if (drug.STEP_THERAPY_YN === 'Y') {
    requirements.push('Step Therapy required');
  }
  const quantityDetails = requirements.find(req => req.startsWith('Quantity Limit Amount')) || '';
  const formattedQuantityDetails = quantityDetails ? quantityDetails.replace('Quantity Limit Amount: ', '').replace(', Quantity Limit Days: ', ' per ') : '';

  return (
    <div>
      {requirements.map((requirement, index) => {
      if (requirement === 'Prior Authorization required') {
        return (
          <div key={index}>
            {requirement}
            <span onClick={() => {
              const popupContent = 'Prior Authorization Function is Working-in-progress';
              // Call handlePopupOpen with the popupContent, but do not send the email yet
              handlePopupOpen(drug.proprietaryname, popupContent);
            }} style={{ cursor: 'pointer', marginLeft: '5px', color: 'blue' }}>
              (Check)
            </span>
          </div>
        );
        } else if (requirement === 'Quantity Limits apply') {
          return (
            <div key={index}>
              QL:{formattedQuantityDetails} days
            </div>
          );
        } else if (requirement.startsWith('Quantity Limit Amount')) {
          return null;
        } else {
          return <div key={index}>{requirement}</div>;
        }
      })}
    </div>
  );
}

// const sendEmailNotification = async (messageDetails) => {
//   try {
//     const response = await fetch('/send-notification-email', {
//       method: 'POST',
//       headers: {
//         'Content-Type': 'application/json',
//       },
//       body: JSON.stringify(messageDetails),
//     });

//     const data = await response.json();
  
//     if (response.ok) {
//       console.log('Email notification sent successfully:', data);
//     } else {
//       console.error('Failed to send email notification:', data.message);
//     }
//   } catch (error) {
//     console.error('Error sending email notification:', error);
//   }
// };

function getFirstRowByProprietaryName(drugs) {
  const selected = new Set();
  const results = [];
  for (const drug of drugs) {
    // Use nonproprietaryname if available; otherwise, use proprietaryname.
    const name = (drug.nonproprietaryname || drug.proprietaryname || '').toLowerCase();
    if (!name) {
      // Skip if there's no name available
      continue;
    }
    if (selected.has(name)) {
      continue;
    }
    selected.add(name);
    results.push(drug);
  }
  return results;
}


// Updated formatCost Function with Logging
const formatCost = (
  costType,
  amount,
  retailCost = null,
  deductible = 0,
  coverageLevel1 = null
) => {
  // Default object in case we return early
  const defaultResult = {
    costDisplay: 'N/A',
    explanation: '',
    isDeductibleStage: false
  };

  // If amount is missing => show 'N/A' unless it's costType=3 with no retail cost
  if (amount === undefined || amount === null || amount === 'N/A') {
    // If costType=3 but we have no valid retailCost => treat as 100% patient responsibility
    if (costType === 3) {
      return {
        costDisplay: '100%',
        explanation: 'No retail cost data found. Patient pays 100% at deductible stage until meet dedutible.',
        isDeductibleStage: true
      };
    }
    return defaultResult;
  }

  // ----------------------------------
  // costType=3 => Deductible Logic
  // ----------------------------------
  if (costType === 3) {
    // If no valid retail cost => treat as 100%
    if (typeof retailCost !== 'number') {
      return {
        costDisplay: '100%',
        explanation: 'No retail cost data found. Patient pays 100% at deductible stage until meet dedutible.',
        isDeductibleStage: true
      };
    }

    // This signals we are in the deductible stage
    let isDeductibleStage = true;

    // 1) If retail cost <= deductible => pay 100% of retail cost
    if (retailCost <= deductible) {
      return {
        costDisplay: `$${retailCost.toFixed(2)}`,
        explanation: `Retail cost ($${retailCost.toFixed(
          2
        )}) <= deductible ($${deductible.toFixed(2)}). Patient pays full cost.`,
        isDeductibleStage
      };
    }

    // 2) If retail cost > deductible => difference = retail - deductible
    const costDifference = retailCost - deductible;
    if (!coverageLevel1) {
      // Fallback if coverage-level-1 missing
      return {
        costDisplay: `$${retailCost.toFixed(2)}`,
        explanation: `No coverage-level-1 info; default to full retail cost.`,
        isDeductibleStage
      };
    }

    let finalCost = 0;
    let explanation = '';
    const cov1Type = parseInt(coverageLevel1.cost_type_pref, 10);
    const cov1Amount = parseFloat(coverageLevel1.cost_amt_pref);

    // coverage-level-1 is a percentage
    if (cov1Type === 2) {
      finalCost = deductible + costDifference * cov1Amount;
      explanation = `Retail cost = $${retailCost.toFixed(
        2
      )} > deductible = $${deductible.toFixed(
        2
      )}. Pay $${deductible.toFixed(
        2
      )} + ${(cov1Amount * 100).toFixed(
        0
      )}% of $${costDifference.toFixed(2)} = $${finalCost.toFixed(2)}.`;
    }
    // coverage-level-1 is a fixed copay
    else if (cov1Type === 1) {
      finalCost = deductible + cov1Amount;
      explanation = `Retail cost = $${retailCost.toFixed(
        2
      )} > deductible = $${deductible.toFixed(
        2
      )}. Pay $${deductible.toFixed(2)} + $${cov1Amount.toFixed(
        2
      )} = $${finalCost.toFixed(2)}.`;
    } else {
      // Fallback if not recognized
      finalCost = retailCost;
      explanation = `Could not determine coverage-level-1 type; default full cost = $${retailCost.toFixed(2)}.`;
    }

    return {
      costDisplay: `$${finalCost.toFixed(2)}`,
      explanation,
      isDeductibleStage
    };
  }

  // ----------------------------------
  // Other cost types
  // ----------------------------------
  if (costType === 0) {
    return {
      costDisplay: `$${parseFloat(amount).toFixed(2)}`,
      explanation: '',
      isDeductibleStage: false
    };
  }

  if (costType === 2) {
    const percentage = parseFloat(amount);
    let display = `${(percentage * 100).toFixed(0)}%`;
    let explanation = '';
    if (retailCost) {
      const costVal = (retailCost * percentage).toFixed(2);
      display += ` ($${costVal})`;
      explanation = `Pay ${(percentage * 100).toFixed(0)}% of $${retailCost.toFixed(2)}.`;
    }
    return {
      costDisplay: display,
      explanation,
      isDeductibleStage: false
    };
  }

  if (costType === 1) {
    return {
      costDisplay: `$${parseFloat(amount).toFixed(2)}`,
      explanation: '',
      isDeductibleStage: false
    };
  }

  // Fallback if unknown
  return {
    costDisplay: amount,
    explanation: '',
    isDeductibleStage: false
  };
};


export const SupplyDurationSelector = ({ value, onChange }) => {
  return (
    <Select value={value} style={{ width: 120 }} onChange={onChange}>
      <Select.Option value="30days">30 Days</Select.Option>
      <Select.Option value="90days">90 Days</Select.Option>
    </Select>
  );
};

export const PharmacyTypeSelector = ({ value, onChange }) => {
  return (
    <Select value={value} style={{ width: 160 }} onChange={onChange}>
      <Select.Option value="retail">Retail Pharmacy</Select.Option>
      <Select.Option value="mail">Mail Pharmacy</Select.Option>
    </Select>
  );
};

const processAndDisplayInsulinCostNew = (insulinCostData, daysSupplyFilter, pharmacyType) => {
  if (!Array.isArray(insulinCostData)) {
    return {
      retailCost: null,
      costBreakdown: [],
      message: 'No insulin cost data available'
    };
  }

  // Determine days supply as numeric: 1 for 30days, 2 for 90days (default to 1)
  const daysSupplyValue = daysSupplyFilter === '30days' ? 1 : (daysSupplyFilter === '90days' ? 2 : 1);

  // Coverage levels for labeling
  const coverageLevels = {
    0: '0. Deductible',
    1: '1. Initial Coverage',
    3: '2. Catastrophic'
  };

  // Prepare coverage info as an array of objects with numeric cost values.
  // We default to null for level 0 and 1 and to 0 for catastrophic (level 3)
  let coverageInfo = [
    { level: '0', preferredCost: null, nonPreferredCost: null },
    { level: '1', preferredCost: null, nonPreferredCost: null },
    { level: '3', preferredCost: 0,    nonPreferredCost: 0 } // Catastrophic is usually $0
  ];

  // Populate coverageInfo from insulinCostData for matching daysSupply
  insulinCostData.forEach(cost => {
    if (cost.days_supply === daysSupplyValue) {
      let rawPreferredCost, rawNonPreferredCost;
      if (pharmacyType === 'retail') {
        rawPreferredCost = cost.copay_amt_pref_insln ?? cost.copay_amt_nonpref_insln;
        rawNonPreferredCost = cost.copay_amt_nonpref_insln;
      } else { // mail pharmacy
        rawPreferredCost = cost.copay_amt_mail_pref_insln ?? cost.copay_amt_mail_nonpref_insln;
        rawNonPreferredCost = cost.copay_amt_mail_nonpref_insln;
      }
      // Convert to a number if possible; otherwise, remain null.
      const numericPreferred = (rawPreferredCost !== null && rawPreferredCost !== undefined)
        ? parseFloat(rawPreferredCost)
        : null;
      const numericNonPreferred = (rawNonPreferredCost !== null && rawNonPreferredCost !== undefined)
        ? parseFloat(rawNonPreferredCost)
        : null;

      // If we found a valid numeric cost, set both level 0 and level 1 costs.
      if (numericPreferred !== null && !isNaN(numericPreferred)) {
        coverageInfo[0].preferredCost = numericPreferred;
        coverageInfo[0].nonPreferredCost = numericNonPreferred;
        coverageInfo[1].preferredCost = numericPreferred;
        coverageInfo[1].nonPreferredCost = numericNonPreferred;
      }
    }
  });

  // If initial coverage (level 1) is missing, then set catastrophic (level 3) to null as well.
  if (coverageInfo[1].preferredCost === null) {
    coverageInfo[2].preferredCost = null;
    coverageInfo[2].nonPreferredCost = null;
  }

  // Use level 1 cost as the retail cost
  let numericRetailCost = coverageInfo[1].preferredCost;

  // Build the costBreakdown array with the proper fields.
  // We now include a numeric "coverage_level" and a "costNumber" field.
  const insulinBreakdown = coverageInfo.map(info => {
    const numericCost = info.preferredCost;
    return {
      coverage_level: parseInt(info.level, 10),  // 0, 1, or 3
      stage: coverageLevels[info.level],
      costNumber: numericCost, // numeric value or null
      costDisplay: (numericCost !== null && !isNaN(numericCost))
        ? `$${numericCost.toFixed(2)}`
        : 'N/A',
      percentageDisplay: null,  // No percentage value for insulin
      explanation: `Insulin cost at ${coverageLevels[info.level]}`,
      isDeductibleStage: parseInt(info.level, 10) === 0
    };
  });

  return {
    retailCost: numericRetailCost, // a number (e.g., 35)
    costBreakdown: insulinBreakdown
  };
};

export const getCoverageLevelWord = (level) => {
  const coverageLevels = {
    0: '0. Deductible',
    1: '1. Initial Coverage',
    // 2: '2. Coverage Gap',
    3: '2. Catastrophic',
  };
  return coverageLevels[level] || 'Unknown';
};
// const patientOutOfPocketCostRender = (record, selectedDaysSupply, selectedPharmacyType, deductible, drugCosts) => {
//   // console.log('Deductible:', deductible);

//   if (!Array.isArray(record.out_of_pocket_cost)) {
//     return 'No cost data available';
//   }

//   const daysSupplyValue = selectedDaysSupply === '30days' ? 1 : 2;
//   const isInsulin = record.out_of_pocket_cost.some(cost => cost.coverage_level === undefined);
  

//   const mailOrderNotOffered = record.out_of_pocket_cost.some(cost =>
//     cost.days_supply === daysSupplyValue &&
//     cost.cost_amt_mail_pref === 'not offered' &&
//     cost.cost_amt_mail_nonpref === 'not offered'
//   );

//   // If the mail order benefit is not offered, display the message and do not render the rest of the table
//   if (mailOrderNotOffered && selectedPharmacyType === 'mail') {
//     return <p>The plan doesn’t offer a mail order benefit.</p>;
//   }

//   if (deductible > 0 && !record.out_of_pocket_cost.some(cost => cost.coverage_level === 0 && cost.days_supply === daysSupplyValue)) {
//     const deductibleStageEntry = {
//       coverage_level: 0,
//       days_supply: daysSupplyValue,
//       cost_type_pref: 3, // This could be a special indicator for deductible stage.
//       cost_type_nonpref: 3, // Assuming the same applies for non-preferred.
//       cost_type_mail_pref: 3, // Assuming the same applies for mail preferred.
//       cost_type_mail_nonpref: 3, // Assuming the same applies for mail non-preferred.
//       cost_amt_pref: '1.00', // 100% cost to patient during the deductible stage.
//       cost_amt_nonpref: '1.00', // Assuming 100% for non-preferred as well.
//       cost_amt_mail_pref: '1.00', // Assuming 100% for mail order preferred.
//       cost_amt_mail_nonpref: '1.00' // Assuming 100% for mail order non-preferred.
//     };
//     record.out_of_pocket_cost.unshift(deductibleStageEntry);
//   }

//   // If the drug is insulin, process using insulin-specific logic
//   if (isInsulin) {
//     return processAndDisplayInsulinCostNew(record.out_of_pocket_cost, selectedDaysSupply, selectedPharmacyType);
//   }

//   const retailCostMap = {};

//   if (Array.isArray(drugCosts)) {
//     console.log("Processing drugCosts for record:", record.nonproprietaryname);

//     drugCosts.forEach(cost => {
//       const drugNameKey = cost.drug_name.toLowerCase();
//       console.log("Mapping drug_name to retail_cost:", {
//         drug_name: cost.drug_name,
//         retail_cost: cost.retail_cost,
//         pharmacy_name: cost.pharmacy_name,
//       });

//       // Initialize drugNameKey if not already in the map
//       if (!retailCostMap[drugNameKey]) {
//         retailCostMap[drugNameKey] = {
//           retail: null, // For retail pharmacy costs
//           mail: null, // For mail order pharmacy costs
//         };
//       }

//       // Assign costs based on pharmacy type
//       if (cost.pharmacy_name.toLowerCase().includes("mail")) {
//         retailCostMap[drugNameKey].mail = parseFloat(cost.retail_cost || 0);
//       } else {
//         retailCostMap[drugNameKey].retail = parseFloat(cost.retail_cost || 0);
//       }
//     });

//     console.log("Completed retailCostMap with pharmacy classification:", retailCostMap);
//   } else {
//     console.log("No drug_costs array found in record:", drugCosts);
//   }

//   // Safely handle null or undefined nonproprietaryname
//   let nonproprietaryKey;
//   if (record.nonproprietaryname) {
//     nonproprietaryKey = record.nonproprietaryname.toLowerCase();
//   } else if (record.proprietaryname) {
//     // Extract main drug name from proprietaryname (use the first word as a heuristic)
//     nonproprietaryKey = record.proprietaryname.split(" ")[0].toLowerCase();
//   } else {
//     nonproprietaryKey = ""; // Default to empty string if neither is available
//   }

//   // console.log("Determined key for matching drug costs:", nonproprietaryKey);
  
//   const matchingCosts = Object.entries(retailCostMap).find(([drugNameKey]) => 
//     drugNameKey.includes(nonproprietaryKey) // Partial string match
//   );

//   // Default to empty object if no match is found
//   const retailCosts = matchingCosts ? matchingCosts[1] : { retail: 0, mail: 0 };

//   console.log("Retail costs for nonproprietaryname with classification:", {
//     nonproprietaryKey,
//     retailCosts,
//   });

//   let selectedPharmacyCost = 0;

//   // Adjust retail cost based on days supply and pharmacy type
//   if (selectedPharmacyType === "mail") {
//     // Mail order cost is for 90 days; adjust if 30 days is selected
//     selectedPharmacyCost = selectedDaysSupply === '30days' 
//       ? (retailCosts.mail !== null && retailCosts.mail !== 0 ? retailCosts.mail / 3 : null) 
//       : (retailCosts.mail !== null && retailCosts.mail !== 0 ? retailCosts.mail : null);
//   } else {
//     // Retail cost is for 30 days; adjust if 90 days is selected
//     selectedPharmacyCost = selectedDaysSupply === '30days' 
//       ? (retailCosts.retail !== null && retailCosts.retail !== 0 ? retailCosts.retail : null) 
//       : (retailCosts.retail !== null && retailCosts.retail !== 0 ? retailCosts.retail * 3 : null);
//   }

//   // Handle case where cost data is missing
//   let selectedPharmacyCostDisplay; // Separate display value
//   if (selectedPharmacyCost === null || selectedPharmacyCost === undefined) {
//     selectedPharmacyCostDisplay = "N/A";
//   } else {
//     selectedPharmacyCostDisplay = `$${selectedPharmacyCost.toFixed(2)}`;
//   }

//   console.log("Adjusted pharmacy cost based on supply and type:", {
//     selectedPharmacyType,
//     selectedDaysSupply,
//     selectedPharmacyCost,
//     selectedPharmacyCostDisplay,
//   });

//   const coverageLevel1Entry = record.out_of_pocket_cost.find(
//     (c) => c.coverage_level === 1 && c.days_supply === daysSupplyValue
//   );

//     // Filter costs based on days supply and coverage level, assuming the array might not be sorted by coverage level
//     const sortedAndFilteredCosts = record.out_of_pocket_cost
//       .filter(cost => cost.days_supply === daysSupplyValue && cost.coverage_level !== 2)
//       .sort((a, b) => a.coverage_level - b.coverage_level);

//     console.log('Sorted and filtered costs:', sortedAndFilteredCosts);

//     // If there's no data after filtering, return a message or a default UI element
//     if (sortedAndFilteredCosts.length === 0) {
//       return 'No cost data available for the selected options';
//     }

//     // Generate table rows for each cost
//     const tableRows = sortedAndFilteredCosts.map((cost, index) => {
//       const coverageWord = getCoverageLevelWord(cost.coverage_level);

//       // Only display retail cost for the first row
//       const retailCostDisplay = index === 0 ? selectedPharmacyCostDisplay : '';

//       let pharmacyKey;
//       if (cost.coverage_level === 0) {
//         pharmacyKey = 'cost_amt_pref'; // Deductible stage always uses preferred cost
//       } else {
//         pharmacyKey = selectedPharmacyType === 'retail' ? 'cost_amt_pref' : 'cost_amt_mail_pref';
//       }
//       const mailOrderNotOffered = (pharmacyKey.includes('mail') && 
//                                       cost.cost_amt_mail_pref === 'not offered' &&
//                                       cost.cost_amt_mail_nonpref === 'not offered');

//       let costCell;
//       // 1) If coverage_level=3 => Catastrophic => $0.00 always
//       if (cost.coverage_level === 3) {
//         costCell = '$0.00';
//       }
//       // 2) If mail order not offered
//       else if (mailOrderNotOffered) {
//         costCell = 'The plan doesn’t offer a mail order benefit';
//       }
//       // 3) Otherwise, use `formatCost`
//       else {
//         const { costDisplay, explanation, isDeductibleStage } = formatCost(
//           cost.cost_type_pref,
//           cost[pharmacyKey],
//           typeof selectedPharmacyCost === 'number' ? selectedPharmacyCost : null,
//           deductible,
//           coverageLevel1Entry
//         );

//         // If costType=3 => show question icon + tooltip
//         if (isDeductibleStage) {
//           costCell = (
//             <AntdTooltip placement="bottom" title={explanation}>
//               <span style={{ cursor: 'pointer' }}>
//                 {costDisplay} <QuestionCircleOutlined style={{ marginLeft: 4 }} />
//               </span>
//             </AntdTooltip>
//           );
//         } else {
//           costCell = costDisplay;
//         }
//       }

//       // if (index === 0) {
//       // // console.log('First cost entry:', cost);
//       // // console.log('First row display:', `${coverageWord}: ${costFormatted}`);
//       // }

//       return (
//       <tr key={index}>
//       <td>{coverageWord}</td>
//       <td>{costCell}</td>
//       <td>{retailCostDisplay}</td> 
//       </tr>
//       );
//       });

//       return (
//         <table>
//         <thead>
//         <tr>
//         <th>Coverage Level</th>
//         <th>{/*costHeader */}</th> {/* Updated header */}
//         <th>Retail Cost</th>
//         </tr>
//         </thead>
//         <tbody>{tableRows}</tbody>
//         </table>
//       );
// };

// const tierLevelDescriptions = {
//   1: 'Preferred Generic Drug',
//   2: 'Generic Drug',
//   3: 'Preferred Brand Drug',
//   4: 'Non-Preferred Drug',
//   5: 'Specialty Tier Drug',
//   6: 'Select Care Drug',
// };

// const tierTooltipContent = (
//   <div>
//     <p>Tier 1: {tierLevelDescriptions[1]}</p>
//     <p>Tier 2: {tierLevelDescriptions[2]}</p>
//     <p>Tier 3: {tierLevelDescriptions[3]}</p>
//     <p>Tier 4: {tierLevelDescriptions[4]}</p>
//     <p>Tier 5: {tierLevelDescriptions[5]}</p>
//     <p>Tier 6: {tierLevelDescriptions[6]}</p>
//   </div>
// );

// ----------------------
// New Helper: computeDrugCost
// ----------------------
// This function mimics the logic in patientOutOfPocketCostRender but returns a computed cost value.
// It returns an object with { drugName, cost } where cost is a number (if available).

function normalizeDrugName(name) {
  if (!name) return "";
  // Convert to lowercase.
  let normalized = name.toLowerCase();
  // Remove digits.
  normalized = normalized.replace(/\d+/g, "");
  // Remove dosage units (mg, ml, etc.)
  normalized = normalized.replace(/\b(mg|ml|mcg|g)\b/g, "");
  // Remove common stopwords (tablet, oral, capsule, injection, etc.)
  normalized = normalized.replace(/\b(tablet|oral|capsule|injection|suspension|solution|strip|kit)\b/g, "");
  // Remove punctuation and extra whitespace.
  // eslint-disable-next-line
  normalized = normalized.replace(/[\/\-\[\]\(\),]/g, " ");
  normalized = normalized.replace(/\s+/g, " ").trim();
  return normalized;
}

function computeDrugCost(record, selectedDaysSupply, selectedPharmacyType, deductible, drugCosts) {
    // Check if the drug is not covered.
    console.log('record:',record)
    if (record.covered === false) {
      return {
        drugName: record.nonproprietaryname || record.proprietaryname || 'Unknown',
        frequency: record.frequency,
        prescribedDate: record.prescribedDate,
        retailCost: null,
        costBreakdown: [],
        notCovered: true
      };
    }

  // Return early if out_of_pocket_cost data is missing.
  if (!Array.isArray(record.out_of_pocket_cost)) {
    return {
      drugName: record.nonproprietaryname || record.proprietaryname || 'Unknown',
      frequency: record.frequency,
      prescribedDate: record.prescribedDate,
      retailCost: null,
      costBreakdown: []
    };
  }
  selectedPharmacyType = selectedPharmacyType || 'retail';

  const daysSupplyValue = record.frequency === '30' ? 1 : 2;
  console.log("computeDrugCost:", record.nonproprietaryname, "frequency:", record.frequency, "-> daysSupplyValue:", daysSupplyValue);

  const isInsulin = record.out_of_pocket_cost.some(cost => cost.coverage_level === undefined);

  const mailOrderNotOffered = record.out_of_pocket_cost.some(cost =>
    cost.days_supply === daysSupplyValue &&
    cost.cost_amt_mail_pref === 'not offered' &&
    cost.cost_amt_mail_nonpref === 'not offered'
  );
  if (mailOrderNotOffered && selectedPharmacyType === 'mail') {
    return {
      drugName: record.nonproprietaryname || record.proprietaryname || 'Unknown',
      retailCost: null,
      costBreakdown: []
    };
  }

  if (deductible > 0 && !record.out_of_pocket_cost.some(cost => cost.coverage_level === 0 && cost.days_supply === daysSupplyValue)) {
    const deductibleStageEntry = {
      coverage_level: 0,
      days_supply: daysSupplyValue,
      cost_type_pref: 3,
      cost_type_nonpref: 3,
      cost_type_mail_pref: 3,
      cost_type_mail_nonpref: 3,
      cost_amt_pref: '1.00',
      cost_amt_nonpref: '1.00',
      cost_amt_mail_pref: '1.00',
      cost_amt_mail_nonpref: '1.00'
    };
    record.out_of_pocket_cost.unshift(deductibleStageEntry);
  }

  // For insulin, you might apply a different logic. For now, we return an empty breakdown.
  if (isInsulin) {
    const insulinCostResult = processAndDisplayInsulinCostNew(record.out_of_pocket_cost, selectedDaysSupply, selectedPharmacyType);
    console.log ('insulinCostResult:',insulinCostResult)
    return {
      drugName: record.nonproprietaryname || record.proprietaryname || 'Unknown',
      frequency: record.frequency,
      prescribedDate: record.prescribedDate,
      retailCost: insulinCostResult.retailCost,
      costBreakdown: insulinCostResult.costBreakdown,
      tier_level_value: record.tier_level_value
    };
  }

  // Build a retail cost map from drugCosts.
  const retailCostMap = {};
  if (Array.isArray(drugCosts)) {
    drugCosts.forEach(cost => {
      // Normalize the cost's drug_name
      const normalizedCostKey = normalizeDrugName(cost.drug_name);
      if (!retailCostMap[normalizedCostKey]) {
        retailCostMap[normalizedCostKey] = { retail: null, mail: null };
      }
      if (cost.pharmacy_name.toLowerCase().includes("mail")) {
        retailCostMap[normalizedCostKey].mail = parseFloat(cost.retail_cost || 0);
      } else {
        retailCostMap[normalizedCostKey].retail = parseFloat(cost.retail_cost || 0);
      }
    });
  }

  let nonproprietaryKey = '';
  if (record.nonproprietaryname) {
    // Remove "(generic)" if present (case-insensitive), then lowercase and trim.
    nonproprietaryKey = record.nonproprietaryname.replace(/\(generic\)/i, "").toLowerCase().trim();
  } else if (record.proprietaryname) {
    nonproprietaryKey = record.proprietaryname.split(" ")[0].toLowerCase();
  }

  const normalizedRecordKey = normalizeDrugName(nonproprietaryKey);

  let matchingCosts;
  if (retailCostMap.hasOwnProperty(normalizedRecordKey)) {
    matchingCosts = [normalizedRecordKey, retailCostMap[normalizedRecordKey]];
  } else {
    // Fallback: find a key that includes our normalizedRecordKey.
    matchingCosts = Object.entries(retailCostMap).find(([drugNameKey]) =>
      drugNameKey.includes(normalizedRecordKey)
    );
  }
  
  const retailCosts = matchingCosts ? matchingCosts[1] : { retail: 0, mail: 0 };

  let selectedPharmacyCost = 0;
  if (selectedPharmacyType === "mail") {
    selectedPharmacyCost = record.frequency === '30' 
      ? (retailCosts.mail !== null && retailCosts.mail !== 0 ? retailCosts.mail / 3 : null) 
      : (retailCosts.mail !== null && retailCosts.mail !== 0 ? retailCosts.mail : null);
  } else {
    selectedPharmacyCost = record.frequency === '30' 
      ? (retailCosts.retail !== null && retailCosts.retail !== 0 ? retailCosts.retail : null) 
      : (retailCosts.retail !== null && retailCosts.retail !== 0 ? retailCosts.retail * 3 : null);
  }

  // Save the computed retail cost to return later.
  const computedRetailCost = selectedPharmacyCost;

  const coverageLevel1Entry = record.out_of_pocket_cost.find(
    (c) => c.coverage_level === 1 && c.days_supply === daysSupplyValue
  );

  // Filter and sort cost rows.
  const sortedAndFilteredCosts = record.out_of_pocket_cost
    .filter(cost => cost.days_supply === daysSupplyValue && cost.coverage_level !== 2)
    .sort((a, b) => a.coverage_level - b.coverage_level);

  if (sortedAndFilteredCosts.length === 0) {
    return {
      drugName: record.nonproprietaryname || record.proprietaryname || 'Unknown',
      retailCost: computedRetailCost,
      costBreakdown: []
    };
  }

  // Map each cost row to a breakdown item.
  const costBreakdown = sortedAndFilteredCosts.map(costRow => {
    let pharmacyKey = costRow.coverage_level === 0 
      ? 'cost_amt_pref' 
      : (selectedPharmacyType === 'retail' ? 'cost_amt_pref' : 'cost_amt_mail_pref');
  
    const costResult = formatCost(
      costRow.cost_type_pref,
      costRow[pharmacyKey],
      typeof selectedPharmacyCost === 'number' ? selectedPharmacyCost : null,
      deductible,
      coverageLevel1Entry
    );
  
    let costNumber = null;
    let percentageDisplay = null;
    if (costResult.costDisplay) {
      // If the display string starts with a dollar sign, extract the numeric value.
      if (costResult.costDisplay.startsWith('$')) {
        costNumber = parseFloat(costResult.costDisplay.replace('$',''));
      } else if (costResult.costDisplay.includes('%')) {
        // Example format: "21% ($196.01)"
        const regex = /^([\d.]+%)\s*\(\$([\d.]+)\)/;
        const match = costResult.costDisplay.match(regex);
        if (match) {
          percentageDisplay = match[1]; // e.g., "21%"
          costNumber = parseFloat(match[2]); // e.g., 196.01
        } else {
          // Fallback: try to extract a $ value even if the regex doesn't match perfectly.
          const dollarRegex = /\$([\d.]+)/;
          const dollarMatch = costResult.costDisplay.match(dollarRegex);
          if (dollarMatch) {
            costNumber = parseFloat(dollarMatch[1]);
          }
        }
      }
    }
    return {
      coverage_level: costRow.coverage_level,
      stage: getCoverageLevelWord(costRow.coverage_level),
      costNumber,
      costDisplay: costResult.costDisplay,
      percentageDisplay,  // new field for the percentage part (if any)
      explanation: costResult.explanation,
      isDeductibleStage: costResult.isDeductibleStage
    };
  });

  return {
    drugName: record.nonproprietaryname || record.proprietaryname || 'Unknown',
    frequency: record.frequency,
    prescribedDate: record.prescribedDate,
    retailCost: computedRetailCost,
    costBreakdown,
    tier_level_value: record.tier_level_value
  };
}


// ----------------------
// New Component: MonthlyBreakdown
function MonthlyBreakdown({ results, selectedDaysSupply, selectedPharmacyType, selectedPlanDetails, drugCosts, paymentPlanStartMonth }) {
  // eslint-disable-next-line
  const [allBreakdownData, setAllBreakdownData] = useState([]);
  const [displayedBreakdownData, setDisplayedBreakdownData] = useState([]);

  const [yearlySummary, setYearlySummary] = useState([]);
  const [simulationWarnings, setSimulationWarnings] = useState([]);
  console.log("Payment Plan Start Month (0-based):", paymentPlanStartMonth);

  // Handle removing a drug from displayedBreakdownData
  const handleRemoveDrug = (drugName) => {
    setDisplayedBreakdownData((prev) => prev.filter((d) => d.drugName !== drugName));
  };

  // 1. Compute breakdownData per drug (individual drug details)
  useEffect(() => {
    const newData = [];
    results.forEach(record => {
      const computed = computeDrugCost(
        record,
        selectedDaysSupply, // now ignored inside computeDrugCost
        selectedPharmacyType,
        selectedPlanDetails?.deductible || 0,
        drugCosts
      );
      // Extract stage values for reference.
      // Log whether there's a percentageDisplay for debugging
      console.log(
        "Drug:",
        computed.drugName,
        "percentageDisplay in coverage stage?",
        computed.costBreakdown.some(item => item.percentageDisplay)
      );
      // Determine the tier level.
      // For covered drugs, we take the first costBreakdown's coverage_level and convert it via getCoverageLevelWord.
      const tierLevel = computed.notCovered
        ? "Not Covered"
        : (record.tier_level_value);
      
        console.log('level:',tierLevel)
      // If the drug is not covered, set related cost fields accordingly.
      if (computed.notCovered) {
        newData.push({
          key: computed.drugName,
          drugName: computed.drugName,
          frequency: computed.frequency,
          prescribedDate: computed.prescribedDate,
          tierLevel,
          retailCost: null,
          deductibleCost: null,
          initialCoverageCost: null,
          percentageDisplay: null,
          costShareLabel: "Not Covered",
          notCovered: true
        });
      } else {
        const deductibleStage = computed.costBreakdown.find(item => item.coverage_level === 0);
        const initialCoverageStage = computed.costBreakdown.find(item => item.coverage_level === 1);
        const dedCost = deductibleStage ? deductibleStage.costNumber : null;
        const initCost = initialCoverageStage ? initialCoverageStage.costNumber : null;
        const perc = initialCoverageStage ? initialCoverageStage.percentageDisplay : null;

        console.log('initialCoverageStage',initialCoverageStage)
        console.log('costshare:',perc)
        let costShareLabel;
        if (dedCost == null && initCost == null) {
          costShareLabel = 'N/A';
        } else {
          costShareLabel = perc ? `Cost share: ${perc}` : 'Co-pay';
        }
        
        newData.push({
          key: computed.drugName,
          drugName: computed.drugName,
          frequency: computed.frequency,
          prescribedDate: computed.prescribedDate,
          tierLevel,
          retailCost: computed.retailCost,
          deductibleCost: dedCost,
          initialCoverageCost: initCost,
          percentageDisplay: perc,
          costShareLabel,
          notCovered: false
      });
      }
    });
    setAllBreakdownData(newData);
  setDisplayedBreakdownData(newData);  // Initially show all
}, [
  results,
  selectedDaysSupply,
  selectedPharmacyType,
  selectedPlanDetails,
  drugCosts
]);

  // 2. Monthly simulation for 2025 – process refill events grouped by month.
  useEffect(() => {
    // Global plan values.
    const planDeductible = selectedPlanDetails?.deductible || 0;
    let remainingDeductible = planDeductible;
    let trueOutOfPocket = 0;
    const catastrophicLimit = 2000;
    
    // Prepare arrays for 12 months.
    const monthlyTotals = Array.from({ length: 12 }, () => 0);
    const monthlyDrugs = Array.from({ length: 12 }, () => []);
    const monthlyExplanations = Array.from({ length: 12 }, () => []);
    const monthlyTrueOOP = Array.from({ length: 12 }, () => 0);
    const monthlyOOPArray = Array.from({ length: 12 }, () => 0); // true OOP for the month only
    
    const startOfYear = new Date(2025, 0, 1);
    const endOfYear = new Date(2025, 11, 31);

    // Filter drugs for simulation.
    // Only include covered drugs that have at least one of the price fields (deductible or initial coverage).
    // Generate warnings for drugs missing all price data or missing retail cost.
    const drugsForSimulation = [];
    const warnings = [];

    displayedBreakdownData.filter(drug => !drug.notCovered).forEach(drug => {
      if (drug.deductibleCost == null && drug.initialCoverageCost == null) {
        warnings.push(`Price data is missing for ${drug.drugName}. We cannot caculate monthly breakdown and M3P. We have documented this issue and will work on supporting this drug in 24 hours.`);
      } else if (drug.retailCost == null) {
      warnings.push(`Retail cost is missing for ${drug.drugName}; We cannot caculate monthly breakdown and M3P, skipping simulation for this drug.`);
      } else {
        drugsForSimulation.push(drug);
        if (drug.retailCost == null) {
          warnings.push(`Retail cost is missing for ${drug.drugName}; True OOP is not reflect correctly. We have documented this issue and will work on supporting this drug in 24 hours.`);
        }
      }
    });
    // Save warnings to state and log them.
    setSimulationWarnings(warnings);
    warnings.forEach(warning => console.warn(warning));

    // Gather refill events from each drug.
    const events = [];
    drugsForSimulation.forEach(drug => {
      const frequencyDays = parseInt(drug.frequency, 10); // frequency is like "30" or "90"
      if (isNaN(frequencyDays) || frequencyDays <= 0) return;
      let eventDate = new Date(drug.prescribedDate);
      // Advance eventDate until it is within 2025.
      while (eventDate < startOfYear) {
        eventDate.setDate(eventDate.getDate() + frequencyDays);
      }
      // Record events until end of 2025.
      while (eventDate <= endOfYear) {
        events.push({
          date: new Date(eventDate),
          drugName: drug.drugName,
          retailCost: drug.retailCost,
          deductibleCost: drug.deductibleCost,
          initialCoverageCost: drug.initialCoverageCost,
          percentageDisplay: drug.percentageDisplay,
          frequency: drug.frequency
        });
        eventDate.setDate(eventDate.getDate() + frequencyDays);
      }
    });
    // Sort events by date.
    events.sort((a, b) => a.date - b.date);
    
    // Group events by month.
    const eventsByMonth = Array.from({ length: 12 }, () => []);
    events.forEach(event => {
      const m = event.date.getMonth();
      eventsByMonth[m].push(event);
    });
    
    for (let m = 0; m < 12; m++) {
      let monthOOP = 0; 
      // eslint-disable-next-line no-loop-func
      eventsByMonth[m].forEach(event => {
        let eventCost = 0;
        let explanationText = "";

        console.log("Processing event for", event.drugName, 
        "retailCost:", event.retailCost, 
        "initialCoverageCost:", event.initialCoverageCost,
        'DeductibleCost:', event.deductibleCost);

        const retail = event.retailCost;
        const initCost = event.initialCoverageCost;
        const percDisplay = event.percentageDisplay;
        const dedCost = event.deductibleCost; // New constant for deductible cost
        
        if (remainingDeductible > 0) {
          // Deductible stage.
          if (retail <= remainingDeductible) {
            // If deductible cost is available, use it; otherwise fall back to retail.
            if (dedCost != null) {
              eventCost = dedCost;
              explanationText = `Deductible stage: deductible cost $${dedCost.toFixed(2)} applied`;
              remainingDeductible -= dedCost;
              trueOutOfPocket += dedCost;
              monthOOP += dedCost;
            } else {
              eventCost = retail;
              explanationText = `Deductible stage: retail cost $${retail.toFixed(2)} fully applied.`;
              remainingDeductible -= retail;
              trueOutOfPocket += retail;
              monthOOP += retail;
            }
          } else {
            const diff = retail - remainingDeductible;
            if (percDisplay) {
              const perc = parseFloat(percDisplay.replace('%','')) / 100;
              eventCost = remainingDeductible + (perc * diff);
              explanationText = `Deductible stage: remaining deductible $${remainingDeductible.toFixed(2)} + ${percDisplay} of diff $${diff.toFixed(2)} = $${eventCost.toFixed(2)}.`;
              trueOutOfPocket += remainingDeductible + (0.25 * diff);
              monthOOP += remainingDeductible + (0.25 * diff);
              remainingDeductible = 0;
            } else {
              const candidate = remainingDeductible + initCost;
              const candidateOOP = remainingDeductible + (0.25 * diff);
              eventCost = candidate;
              explanationText = `Deductible stage (no percentage): remaining deductible $${remainingDeductible.toFixed(2)} + initial coverage cost $${(initCost || 0).toFixed(2)} = $${candidate.toFixed(2)}; OOP candidate $${candidateOOP.toFixed(2)}; using $${eventCost.toFixed(2)}.`;
              trueOutOfPocket += Math.max(candidateOOP, candidate);
              monthOOP += Math.max(candidateOOP, candidate);
              remainingDeductible = 0;
            }
          }
        } else if (trueOutOfPocket < catastrophicLimit) {
          // Initial coverage stage.
          const potentialIncrease = 0.25 * retail;
          const remainingTrOOP = catastrophicLimit - trueOutOfPocket;
          // Base event cost is initial coverage cost.
          eventCost = initCost;
          explanationText = `Initial coverage stage: base event cost = $${initCost.toFixed(2)}; `;
          if (potentialIncrease > remainingTrOOP) {
            // If potential increase exceeds remaining threshold, then adjust.
            if (percDisplay) {
              eventCost = Math.min(remainingTrOOP, initCost);
              explanationText += `capped by remaining TrOOP ($${remainingTrOOP.toFixed(2)}).`;
            } else {
              explanationText += `event cost remains at $${initCost.toFixed(2)}.`;
            }
            trueOutOfPocket += remainingTrOOP;
            monthOOP += remainingTrOOP;
          } else {
            explanationText += `true OOP increases by 0.25×retail $${potentialIncrease.toFixed(2)}.`;
            trueOutOfPocket += potentialIncrease;
            monthOOP += potentialIncrease;
          }
        } else {
          // Catastrophic stage.
          eventCost = 0;
          explanationText = `Catastrophic stage reached; event cost = $0.`;
        }
        
        
        // // Cap eventCost by remaining TrOOP.
        // const remainingTrOOP = catastrophicLimit - trueOutOfPocket;
        // if (eventCost > remainingTrOOP) {
        //   if (percDisplay) {
        //     explanationText += ` Capped by remaining TrOOP ($${remainingTrOOP.toFixed(2)}).`;
        //     eventCost = remainingTrOOP;
        //   }
        //   trueOutOfPocket = catastrophicLimit;
        // }
        
        monthlyTotals[m] += eventCost;
        if (!monthlyDrugs[m].includes(event.drugName)) {
          monthlyDrugs[m].push(event.drugName);
        }
        monthlyExplanations[m].push(`On ${event.date.toLocaleDateString()} for ${event.drugName}: ${explanationText}`);
      });
      // Record cumulative true OOP at end of month.
      monthlyTrueOOP[m] = trueOutOfPocket;
          // Save the true OOP for this month only.
      monthlyOOPArray[m] = monthOOP;
    }
    
    // Build summary rows for each month.
    const monthNames = ["January", "February", "March", "April", "May", "June",
                        "July", "August", "September", "October", "November", "December"];
    let summaryRows = monthNames.map((name, idx) => ({
      key: name,
      month: name,
      cost: monthlyTotals[idx],
      drugs: monthlyDrugs[idx].join(', '),
      trueOOP: monthlyTrueOOP[idx],
      monthlyOOP: monthlyOOPArray[idx], // true OOP for this month only
      explanation: monthlyExplanations[idx].join(' | ')
    }));
    
    // Now, compute Payment Plan values.
    // Assume totalMonths = 12 and annualOOPThreshold is provided (or default to 0 if not available).
    const totalMonths = 12;
    const annualOOPThreshold = 2000;
    let remainingBalanceDue = 0; 
    let cumulativeTrueOOPPaid = 0;

    console.log("Payment Plan Start Month (0-based):", paymentPlanStartMonth);
    
    // Process each month (using summaryRows from index 0 to 11)
    summaryRows = summaryRows.map((row, index) => {
      // For months before the payment plan start, we don't apply payment plan.
      if (index < paymentPlanStartMonth) {
        cumulativeTrueOOPPaid += row.cost;
        return { ...row, m3pMonthlyCap: null, m3pBill: null, balanceInM3P: null, m3pExplanation: "Payment plan not started."};
      } else {
        const monthsRemaining = totalMonths - index;
        if (index === paymentPlanStartMonth) {
          const amountToReachThreshold = annualOOPThreshold - cumulativeTrueOOPPaid;
          const monthlyCap = amountToReachThreshold > 0 ? amountToReachThreshold / monthsRemaining : 0;
          const m3pBill = Math.min(monthlyCap, row.cost + (remainingBalanceDue / monthsRemaining));
          const balanceInM3P = remainingBalanceDue + (row.cost - m3pBill);
          cumulativeTrueOOPPaid += row.cost;
          remainingBalanceDue = balanceInM3P;
          const m3pExplanation = `Starting Payment Plan: cumulative OOP before this month = $${(cumulativeTrueOOPPaid - row.cost).toFixed(2)}; amount needed = $${amountToReachThreshold.toFixed(2)}; with ${monthsRemaining} months remaining, monthly cap = $${monthlyCap.toFixed(2)}; then M3P Bill = min($${monthlyCap.toFixed(2)}, $${row.cost.toFixed(2)} + ($${(remainingBalanceDue / monthsRemaining).toFixed(2)})) = $${m3pBill.toFixed(2)}; new balance = $${balanceInM3P.toFixed(2)}.`;
          return { ...row, m3pMonthlyCap: monthlyCap, m3pBill: m3pBill, balanceInM3P: balanceInM3P, m3pExplanation };
        } else {
          const monthsRemaining = totalMonths - index;
          const adjustedRemainingBalance = remainingBalanceDue + row.cost;
          const monthlyCap = adjustedRemainingBalance / monthsRemaining;
          const m3pBill = Math.min(monthlyCap, row.cost + (remainingBalanceDue / monthsRemaining));
          const balanceInM3P = remainingBalanceDue + (row.cost - m3pBill);
          cumulativeTrueOOPPaid += row.cost;
          remainingBalanceDue = balanceInM3P;
          const m3pExplanation = `Month ${row.month}: adjusted balance = $${adjustedRemainingBalance.toFixed(2)}; with ${monthsRemaining} months remaining, monthly cap = $${monthlyCap.toFixed(2)}; M3P Bill = min($${monthlyCap.toFixed(2)}, $${row.cost.toFixed(2)} + ($${(remainingBalanceDue / monthsRemaining).toFixed(2)})) = $${m3pBill.toFixed(2)}; new balance = $${balanceInM3P.toFixed(2)}.`;
          return { 
            ...row, 
            m3pMonthlyCap: monthlyCap, 
            m3pBill: m3pBill, 
            balanceInM3P: balanceInM3P,
            m3pExplanation 
          };
        }
      }
    });

    // Calculate total M3P Bill across months.
    const totalM3PBill = summaryRows.reduce((sum, row) => sum + (row.m3pBill || 0), 0);
    
    // Append annual total row.
    const totalAnnual = monthlyTotals.reduce((sum, v) => sum + v, 0);
    summaryRows.push({
      key: "Annual",
      month: "Annual Total",
      cost: totalAnnual,
      drugs: "",
      trueOOP: trueOutOfPocket,
      explanation: "",
      m3pMonthlyCap: null,
      m3pBill: totalM3PBill,
      balanceInM3P: remainingBalanceDue,
      m3pExplanation: null,
    });
    
     setYearlySummary(summaryRows);
}, [displayedBreakdownData, selectedPlanDetails, paymentPlanStartMonth]);

  const chartData = yearlySummary
    // filter out the annual row if needed
    .filter(row => row.month !== "Annual Total")
    .map(row => ({
      month: row.month,
      outOfPocket: row.cost,
      m3pBill: row.m3pBill,
    }));

  // 3. Define table columns.
  const stageColumns = [
    { title: 'Drug Name', dataIndex: 'drugName', key: 'drugName' },
    { title: 'Frequency', dataIndex: 'frequency', key: 'frequency' },
    {
      title: 'Start Date',
      dataIndex: 'prescribedDate',
      key: 'prescribedDate',
      render: dateVal => dateVal ? (new Date(dateVal)).toLocaleDateString() : 'N/A'
    },
    {
      title: 'Tier Level',
      dataIndex: 'tierLevel',
      key: 'tierLevel'
    },
    {
      title: 'Deductible Cost',
      dataIndex: 'deductibleCost',
      key: 'deductibleCost',
      render: cost => (typeof cost === 'number' ? `$${cost.toFixed(2)}` : 'N/A')
    },
    {
      title: 'Initial Coverage Cost',
      dataIndex: 'initialCoverageCost',
      key: 'initialCoverageCost',
      render: cost => (typeof cost === 'number' ? `$${cost.toFixed(2)}` : 'N/A')
    },
    {
      title: 'Cost Share Type',
      dataIndex: 'costShareLabel', // use the new field
      key: 'costShareLabel',
      render: val => val || 'Co-pay'
    },
    {
      title: 'Retail Cost',
      dataIndex: 'retailCost',
      key: 'retailCost',
      render: cost => (typeof cost === 'number' ? `$${cost.toFixed(2)}` : 'N/A')
    },
    {
      title: 'Action',
      key: 'action',
      render: (_, record) => (
        <Button
          style={{ 
            backgroundColor: '#f5f5f5', 
            color: '#000', 
            borderColor: '#f5f5f5' 
          }}
          onClick={() => handleRemoveDrug(record.drugName)}
        >
          Remove
        </Button>
      )
    }
  ];

  const yearlyColumns = [
    { title: 'Month', dataIndex: 'month', key: 'month' },
    { title: 'Drugs Refilled', dataIndex: 'drugs', key: 'drugs' },
    { 
      title: (
        <AntdTooltip placement="bottom" title="The Monthly Total Amount patients pay at the pharmacy if not enrolled in M3P.">
          Out-of-Pocket (w/o M3P) <QuestionCircleOutlined />
        </AntdTooltip>
      ),
      dataIndex: 'cost', 
      key: 'cost', 
      onCell: () => ({
        style: { backgroundColor: '#f5f5f5' },
      }),
      render: (cost, record) => (
        <AntdTooltip title={record.explanation}>
          {cost != null ? `$${cost.toFixed(2)}` : 'N/A'}
        </AntdTooltip>
      ) 
    },
    { 
      title: (
        <AntdTooltip placement="bottom" title="The amount from the current month that counts toward overall True out-of-pocket total.">
          Monthly True OOP <QuestionCircleOutlined />
        </AntdTooltip>
      ),
      dataIndex: 'monthlyOOP', 
      key: 'monthlyOOP', 
      render: val => (val != null ? `$${val.toFixed(2)}` : 'N/A')
    },
    { title: (
      <AntdTooltip placement="bottom" title="This is the running total of True Out-of-pocket spending since the start of the year. Once patients reach a $2,000 total, they won’t have to pay for covered medications for the rest of the year.">
        True OOP Sum <QuestionCircleOutlined />
      </AntdTooltip>
      ),
     dataIndex: 'trueOOP', key: 'trueOOP', render: val => (val != null ? `$${val.toFixed(2)}` : 'N/A') },
    { title: (
      <AntdTooltip placement="bottom" title="The Maximum Amount patients would be charged in this month if in M3P.">
        M3P Monthly Cap <QuestionCircleOutlined />
      </AntdTooltip>
      ),
     dataIndex: 'm3pMonthlyCap', key: 'm3pMonthlyCap',  
    render: cap => (cap != null ? `$${cap.toFixed(2)}` : 'N/A') },
    { 
      title: (
        <AntdTooltip placement="bottom" title="The actual bill that patients will receive under M3P. It is the lower amount between the regular out-of-pocket cost and the M3P monthly cap.">
          M3P Bill (w/ M3P) <QuestionCircleOutlined />
        </AntdTooltip>
      ),
      dataIndex: 'm3pBill', 
      key: 'm3pBill', 
      onCell: () => ({
        style: { backgroundColor: '#f5f5f5' },
      }),
      render: (bill, record) => (
        <AntdTooltip title={record.m3pExplanation || "No M3P explanation available"}>
          {bill != null ? `$${bill.toFixed(2)}` : 'N/A'}
        </AntdTooltip>
      ) 
    },
    { title: (
      <AntdTooltip placement="bottom" title="The remaining balance in M3P plan after applying the current month's M3P Bill.">
        Balance in M3P <QuestionCircleOutlined />
      </AntdTooltip>
      ),
     dataIndex: 'balanceInM3P', key: 'balanceInM3P',
    render: bal => (bal != null ? `$${bal.toFixed(2)}` : 'N/A') }
  ];


  return (
    <div>
      <h3>Drug Cost Breakdown</h3>
      <Table dataSource={displayedBreakdownData} columns={stageColumns} pagination={false} />

      {/* Render simulation warnings, if any */}
      {simulationWarnings.length > 0 && (
        <div style={{ marginBottom: '1rem', color: 'red' }}>
          <strong>Warning:</strong>
          <ul>
            {simulationWarnings.map((warning, idx) => <li key={idx}>{warning}</li>)}
          </ul>
        </div>
      )}

      <h3 style={{ marginTop: '2rem' }}>Monthly Breakdown (Jan - Dec 2025)</h3>
      <p>
        *All costs are estimates for reference.
      </p>
      <Table dataSource={yearlySummary} columns={yearlyColumns} pagination={false} />

      {/* 6. Render the chart below the table */}
      {chartData.length > 0 && (
        <PaymentPlanChart
          data={chartData}
          paymentPlanStartMonth={paymentPlanStartMonth}
        />
      )}
    </div>
  );
}

// function handleRemoveDrug(drugName) {
//   // Filter it out of displayedBreakdownData
//   setDisplayedBreakdownData(prev =>
//     prev.filter(d => d.drugName !== drugName)
//   );
// }

// function handleAddDrug(drugName) {
//   // Find the drug in allBreakdownData
//   const drugToAdd = allBreakdownData.find(d => d.drugName === drugName);
//   if (drugToAdd) {
//     // If it's not already in displayedBreakdownData, add it
//     setDisplayedBreakdownData(prev => {
//       const alreadyExists = prev.some(d => d.drugName === drugName);
//       return alreadyExists ? prev : [...prev, drugToAdd];
//     });
//   }
// }

// const resultTableColumns = (selectedDaysSupply, selectedPharmacyType, handlePopupOpen, selectedInsurancePlan, selectedPlanDetails, drugCosts) => {
//   console.log('Selected Plan Details:', selectedPlanDetails);
//   console.log('Deductible value being passed:', selectedPlanDetails ? selectedPlanDetails.deductible : 0);
//   return [
//     {
//       dataIndex: 'proprietaryname',
//       title: 'Drug Name',
//       render(value, record) {
//         const displayValue = record.nonproprietaryname || value; // Use nonproprietaryname if available, otherwise proprietaryname
//         return (
//           <div>
//             {record.nonproprietaryname
//               ? <strong>{displayValue}</strong> // Bold if nonproprietaryname is available
//               : displayValue // Normal text if nonproprietaryname is missing
//             }
//           </div>
//         );
//       },
//     },
//     {
//       dataIndex: 'tier_level_value',
//       title: (
//         <AntdTooltip placement="bottom" title={tierTooltipContent}>
//           Tier Level Value <QuestionCircleOutlined />
//         </AntdTooltip>
//       ),
//       render: (tierLevelValue, record) => {
//         // Return only 'Not Covered' if the tier level is not available, without 'Unknown Tier'
//         if (tierLevelValue === 'Not Covered') return 'Not Covered';
        
//         const description = tierLevelDescriptions[tierLevelValue];
//         const deductible = selectedPlanDetails?.deductible || 0; // Safely access deductible or default to 0

//         // Ensure out_of_pocket_cost is an array before calling .some()
//         const outOfPocketCostArray = Array.isArray(record.out_of_pocket_cost) ? record.out_of_pocket_cost : [];
//         const hasDeductibleExclusion = outOfPocketCostArray.some(cost => cost.ded_applies_yn === 'N');

//         // Only show the message if there is a deductible
//         const deductibleMessage =
//           deductible > 0 && hasDeductibleExclusion ? (
//             <div style={{ fontSize: 'smaller', color: '#888', marginTop: '4px' }}>
//               (Deductible does not apply to this tier)
//             </div>
//           ) : null;
//         return (
//           <div>
//             {description ? `${tierLevelValue} (${description})` : tierLevelValue}
//             {deductibleMessage}
//           </div>
//         );
//       },
//     },
//     {
//       dataIndex: 'out_of_pocket_cost',
//       title: (
//         <div>
//           Patient Out-of-pocket ($)
//           <div style={{ fontSize: 'smaller', color: '#888', lineHeight: 1.5 }}>
//             <div>(All costs are estimates for reference)</div>
//             <div>(% is the percentage of the drug retail price)</div>
//           </div>
//         </div>
//       ),
//       render: (_, record) => patientOutOfPocketCostRender(
//         record, 
//         selectedDaysSupply, 
//         selectedPharmacyType, 
//         selectedPlanDetails ? selectedPlanDetails.deductible : 0,
//         drugCosts
//       ),
//     },
//     {
//       dataIndex: 'active_ingred_unit',
//       title: 'Coverage Requirements',
//       render: (_, drug) => {
//         return drug.covered ? renderRequirements(drug, selectedInsurancePlan, handlePopupOpen) : '';
//       },
//     },
//   ];
// };


function getCustomTierSortOrder(hasTierSix) {
  return hasTierSix ? [1, 2, 6, 3, 4, 5, 'Not Covered'] : [1, 2, 3, 4, 5, 6, 'Not Covered'];
  }

function sortByTier(groups, sortOrder) {
  return groups.sort((a, b) => {
    let tierA = a.tier_level_value === 'Not Covered' ? 'Not Covered' : parseInt(a.tier_level_value, 10);
    let tierB = b.tier_level_value === 'Not Covered' ? 'Not Covered' : parseInt(b.tier_level_value, 10);

    let indexA = sortOrder.indexOf(tierA);
    let indexB = sortOrder.indexOf(tierB);

    if (indexA === -1) indexA = sortOrder.length;
    if (indexB === -1) indexB = sortOrder.length;

    return indexA - indexB;
  });
}

export default function DrugClassResultsTable({ results, selectedInsurancePlan, selectedPlanDetails, expandedRow, setExpandedRow, drugCosts, drugsData, paymentPlanStartMonth }) {
  useEffect(() => {
    console.log("Updated results in DrugClassResultsTable:", results);
  }, [results]);

  
  // const toggleDetails = (nameKey) => {
  //   setExpandedRow(expandedRow === nameKey ? null : nameKey);
  // };

  const [tierDetailsModalVisible, setTierDetailsModalVisible] = useState(false);
  // const [tierDetails, setTierDetails] = useState(null);
  const [supplyDuration, setSupplyDuration] = useState('30days'); // Default value
  const [pharmacyType, setPharmacyType] = useState('retail'); // Default value
  // const [selectedDrugName, setSelectedDrugName] = useState('');
  const [selectedDaysSupply,] = useState('30days'); 
  const [selectedPharmacyType,] = useState('retail'); 
  // const [visiblePopup, setVisiblePopup] = useState(false);
  // const [popupContent, setPopupContent] = useState('');
  // const [popupTitle, setPopupTitle] = useState('');
  const hasTierSix = results.some(drug => drug.tier_level_value === 6);
  const sortOrder = getCustomTierSortOrder(hasTierSix);
  const sortedGroupedDrugResults = sortByTier(getFirstRowByProprietaryName(results), sortOrder);
  
  // const handlePopupClose = () => {
  //   setVisiblePopup(false);

  //   if (popupTitle.includes('Working-in-progress')) {
  //     // The popup was for the prior authorization check, so send the email
  //     sendEmailNotification(selectedDrugName);
  //   }
  //   if (popupContent && selectedDrugName) {
  //     sendEmailNotification({
  //       subject: 'Requirement Details Accessed',
  //       body: `The details for ${selectedDrugName} were accessed: ${popupContent}`
  //     });
  //   }
  // };
  
  // const handlePopupOpen = (drugName, content) => {
  //   setSelectedDrugName(drugName);
  //   setPopupContent(content);
  //   setPopupTitle(`Requirement Details for ${drugName}`);
  //   setVisiblePopup(true);
  // };

  // const handleSupplyDurationChange = (newValue) => {
  //   setSelectedDaysSupply(newValue);
  //   // additional logic to refresh the data if necessary
  // };
  
  // const handlePharmacyTypeChange = (newValue) => {
  //   setSelectedPharmacyType(newValue);
  //   // additional logic to refresh the data if necessary
  // };

  
  return (
  <div>
    {/* <SupplyDurationSelector value={selectedDaysSupply} onChange={handleSupplyDurationChange} />
    <PharmacyTypeSelector value={selectedPharmacyType} onChange={handlePharmacyTypeChange} /> */}

    {/* <Modal
        title={popupTitle} // Use the state variable for the title
        visible={visiblePopup}
        onOk={() => {
          handlePopupClose();
          // If you want to send an email when OK is clicked, you can call sendEmailNotification here.
          sendEmailNotification({
            subject: 'Patient Assistance Program Accessed',
            body: `The patient assistance program for ${selectedDrugName} was accessed.`
          });
        }}
        onCancel={handlePopupClose} // Optionally keep the cancel button behavior the same as OK
        footer={[
          <Button key="submit" type="primary" onClick={handlePopupClose}>
            OK
          </Button>,
        ]}
      >
        <p dangerouslySetInnerHTML={{ __html: popupContent }}></p>
      </Modal> */}
    {/* <Table
      columns={resultTableColumns(selectedDaysSupply, selectedPharmacyType, handlePopupOpen, selectedInsurancePlan, selectedPlanDetails, drugCosts)}
      dataSource={sortedGroupedDrugResults}
      rowKey="proprietaryname" 
      pagination={false} 
    /> */}
    {Array.isArray(sortedGroupedDrugResults) && sortedGroupedDrugResults.length > 0 ? (
      <MonthlyBreakdown
        results={sortedGroupedDrugResults}  // <--- Pass your actual array as 'results'
        selectedDaysSupply={selectedDaysSupply}
        selectedPharmacyType={selectedPharmacyType}
        selectedPlanDetails={selectedPlanDetails}
        drugCosts={drugCosts}
        paymentPlanStartMonth={paymentPlanStartMonth} 
      />
    ) : (
      <p style={{ marginTop: '2rem', fontStyle: 'italic' }}>
        No drugs entered yet. Please add at least one drug to see the monthly breakdown.
      </p>
    )}


    <Modal
      title="Beneficiary Cost Details"
      visible={tierDetailsModalVisible}
      onOk={() => setTierDetailsModalVisible(false)}
      onCancel={() => setTierDetailsModalVisible(false)}
      footer={null}
    >
      {/* Display Deductible information at the top */}
      {selectedPlanDetails.deductible !== null && selectedPlanDetails.deductible !== undefined && (
        <div>
          <strong>Annual Deductible:</strong> ${selectedPlanDetails.deductible.toFixed(2)}
        </div>
      )}
      {/* Supply Duration and Pharmacy Type Selectors within the Modal */}
      <SupplyDurationSelector value={supplyDuration} onChange={setSupplyDuration} />
      <PharmacyTypeSelector value={pharmacyType} onChange={setPharmacyType} />
    </Modal>
    </div>
  );
};