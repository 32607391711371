import { request } from './shared';

export function searchDrugClassInfoByPartDCode(drug, formulary_id, contract_id, plan_id, segment_id, zipCode) {
  return request({
    data: {
      drug_class: drug,
      formulary_id: formulary_id,
      contract_id: contract_id, // added
      plan_id: plan_id,         // added
      segment_id: segment_id,    // added
      zipCode: zipCode
    },
    method: 'POST',
    url: '/search-drug-class-info-by-partd-code',
  });
}