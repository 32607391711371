import { request } from './shared';

export function m3pmultiSearchDrugInfo(countyCode, drugs, formulary_id, contract_id, plan_id, segment_id) {
  const drug_names = drugs.map(drug => drug.drug);               // e.g. "Ozempic / semaglutide"
  const frequencies = drugs.map(drug => drug.frequency);           // e.g. "30"
  const prescribedDates = drugs.map(drug => drug.prescribedDate);

  return request({
    data: {
      county_code: countyCode,
      drug_names,
      frequencies,
      prescribedDates, 
      formulary_id: formulary_id,
      contract_id: contract_id, // added
      plan_id: plan_id,         // added
      segment_id: segment_id 
    },
    method: 'POST',
    url: '/m3p-multi-search-drug-info',
  });
}