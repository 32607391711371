import React from 'react';
import { Modal, Button } from 'antd';

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { error: null, errorInfo: null, visible: false };
  }

  componentDidCatch(error, errorInfo) {
    // Log the error to an error reporting service if needed.
    console.error("ErrorBoundary caught an error", error, errorInfo);
    this.setState({ error, errorInfo, visible: true });
  }

  handleClose = () => {
    // You could also choose to preserve the data in localStorage or via Redux.
    this.setState({ visible: false });
  };

  render() {
    if (this.state.errorInfo) {
      return (
        <>
          {this.props.children}
          <Modal
            visible={this.state.visible}
            title="Oops, something went wrong"
            onOk={this.handleClose}
            onCancel={this.handleClose}
            footer={[
              <Button key="ok" type="primary" onClick={this.handleClose}>
                OK
              </Button>
            ]}
          >
            <p>
              An unexpected error has occurred. We have documented this problem and are working to fix it ASAP.
            </p>
            <p>
              So sorry for this error!
            </p>
          </Modal>
        </>
      );
    }
    return this.props.children;
  }
}

export default ErrorBoundary;
