import { request } from './shared';

export function m3pmultiSearchDrugInfoByPartDCode(drugs, formulary_id, contract_id, plan_id, segment_id, zipCode) {
  const drug_names = drugs.map(drug => drug.drug);               // e.g. "Ozempic / semaglutide"
  const frequencies = drugs.map(drug => drug.frequency);           // e.g. "30"
  const prescribedDates = drugs.map(drug => drug.prescribedDate);    // e.g. a Date or string value

  return request({
    data: {
      drug_names,      // Array of drug names
      frequencies,     // Array of frequencies (as strings or numbers)
      prescribedDates, // Array of prescribed dates (string or date format)
      formulary_id,
      contract_id,
      plan_id,
      segment_id,
      zipCode,
    },
    method: 'POST',
    url: '/m3p-multi-search-drug-info',
  });
}
