import './index.css';
import { QuestionCircleOutlined } from '@ant-design/icons';
import { Modal, Table, Select, Button, Tooltip } from 'antd'; 
import React, { useState, useEffect } from 'react';
// import { Link } from 'react-router-dom';
// import { api, useAPI } from '../../api';


// const drugsWithPatientAssistance = [
//   { name: "BYDUREON Bcise", url: "https://www.azandmeapp.com/prescriptionsavings/?screenName=showEnrollmentPage" },
//   { name: "Trulicity", url: "https://www.lillycares.com/how-to-apply#check-eligibility" },
//   { name: "Ozempic", url: "https://www.novocare.com/diabetes/help-with-costs/pap.html" },
//   { name: "RYBELSUS", url: "https://www.novocare.com/diabetes/help-with-costs/pap.html" },
//   { name: "Soliqua 100/33", url: "https://www.sanofipatientconnection.com/media/pdf/SPC_Application.pdf" },
//   { name: "Xultophy 100/3.6", url: "https://www.novocare.com/diabetes/help-with-costs/pap.html" },
//   { name: "Jentadueto", url: "https://www.boehringer-ingelheim.com/us/about-us/sustainable-development/our-commitment/bi-cares-patient-assistance-portal" },
//   { name: "Jentadueto XR", url: "https://www.boehringer-ingelheim.com/us/about-us/sustainable-development/our-commitment/bi-cares-patient-assistance-portal" },
//   { name: "Glyxambi", url: "https://www.boehringer-ingelheim.com/us/about-us/sustainable-development/our-commitment/bi-cares-patient-assistance-portal" },
//   { name: "JANUMET", url: "https://www.merckhelps.com/JANUMET" },
//   { name: "JANUMET XR", url: "https://www.merckhelps.com/JANUMET" },
//   { name: "KAZANO", url: "https://www.helpathandpap.com/About.aspx" },
//   { name: "OSENI", url: "https://www.helpathandpap.com/About.aspx" },
//   { name: "Jardiance", url: "https://www.boehringer-ingelheim.com/us/our-responsibility/patient-assistance-program" },
//   { name: "Synjardy", url: "https://www.boehringer-ingelheim.com/us/our-responsibility/patient-assistance-program" },
//   { name: "FARXIGA", url: "https://www.azandmeapp.com/prescriptionsavings/?screenName=showEnrollmentPage" },
//   { name: "Trijardy XR", url: "https://www.boehringer-ingelheim.com/us/our-responsibility/patient-assistance-program" },
//   { name: "XIGDUO", url: "https://www.azandmeapp.com/prescriptionsavings/?screenName=showEnrollmentPage" },
//   { name: "INVOKAMET", url: "https://www.jjpaf.org/eligibility/?medication=INVOKAMET%C2%AE#step1" },
//   { name: "INVOKAMET XR", url: "https://www.jjpaf.org/eligibility/?medication=INVOKAMET%C2%AE%20XR#step1" },
//   { name: "Fiasp", url: "https://www.novocare.com/diabetes/help-with-costs/pap.html" },
//   { name: "LYUMJEV", url: "https://www.lillycares.com/how-to-apply#choose-application" },
//   { name: "NOVOLOG", url: "https://www.novocare.com/diabetes/help-with-costs/pap.html" },
//   { name: "Humalog", url: "https://www.lillycares.com/how-to-apply#choose-application" },
//   { name: "ADMELOG", url: "https://www.sanofipatientconnection.com/media/pdf/SPC_Application.pdf" },
//   { name: "Apidra", url: "https://www.sanofipatientconnection.com/media/pdf/SPC_Application.pdf" },
//   { name: "Levemir", url: "https://www.novocare.com/diabetes/help-with-costs/pap.html" },
//   { name: "Lantus", url: "https://www.sanofipatientconnection.com/patient-assistance-connection" },
//   { name: "BASAGLAR", url: "https://www.lillycares.com/how-to-apply#choose-application" },
//   { name: "SEMGLEE", url: "https://www.bioconbiologicsus.com/pap" },
//   { name: "Rezvoglar", url: "https://www.lillycares.com/how-to-apply#choose-application" },
//   { name: "Tresiba", url: "https://www.novocare.com/diabetes/help-with-costs/pap.html" },
//   { name: "TOUJEO", url: "https://www.sanofipatientconnection.com/patient-assistance-connection" },
//   { name: "TOUJEO Max", url: "https://www.sanofipatientconnection.com/patient-assistance-connection" },
//   { name: "Humulin", url: "https://www.lillycares.com/how-to-apply#choose-application" },
//   { name: "NovoLog Mix 70/30", url: "https://www.novocare.com/diabetes/help-with-costs/pap.html" },
//   { name: "AFREZZA", url: "https://afrezza.com/paying-for-afrezza/" },
//   { name: "Insulin Lispro Protamine and Insulin Lispro Injectable Suspension", url: "https://www.lillycares.com/how-to-apply#choose-application" },
// ].map(drug => ({ ...drug, name: drug.name.toLowerCase() }));




export function renderRequirements(drug, selectedInsurancePlan, handlePopupOpen, sendEmailNotification) {
  const requirements = Array.isArray(drug.requirements) ? drug.requirements : [drug.requirements];
  if (drug.STEP_THERAPY_YN === 'Y') {
    requirements.push('Step Therapy required');
  }
  const quantityDetails = requirements.find(req => req.startsWith('Quantity Limit Amount')) || '';
  const formattedQuantityDetails = quantityDetails ? quantityDetails.replace('Quantity Limit Amount: ', '').replace(', Quantity Limit Days: ', ' per ') : '';

  return (
    <div>
      {requirements.map((requirement, index) => {
      if (requirement === 'Prior Authorization required') {
        return (
          <div key={index}>
            {requirement}
            <span onClick={() => {
              const popupContent = 'Prior Authorization Function is Working-in-progress';
              // Call handlePopupOpen with the popupContent, but do not send the email yet
              handlePopupOpen(drug.proprietaryname, popupContent);
            }} style={{ cursor: 'pointer', marginLeft: '5px', color: 'blue' }}>
              (Check)
            </span>
          </div>
        );
        } else if (requirement === 'Quantity Limits apply') {
          return (
            <div key={index}>
              QL:{formattedQuantityDetails} days
            </div>
          );
        } else if (requirement.startsWith('Quantity Limit Amount')) {
          return null;
        } else {
          return <div key={index}>{requirement}</div>;
        }
      })}
    </div>
  );
}

const sendEmailNotification = async (messageDetails) => {
  try {
    const response = await fetch('/send-notification-email', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(messageDetails),
    });

    const data = await response.json();
  
    if (response.ok) {
      console.log('Email notification sent successfully:', data);
    } else {
      console.error('Failed to send email notification:', data.message);
    }
  } catch (error) {
    console.error('Error sending email notification:', error);
  }
};

function getFirstRowByProprietaryName(drugs) {
  const selected = new Set();
  const results = [];
  for (const drug of drugs) {
    const name = drug.proprietaryname.toLowerCase();
    if (selected.has(name)) {
      continue;
    }
    selected.add(name);
    results.push(drug);
  }
  return results;
}


// Updated formatCost Function with Logging
const formatCost = (
  costType,
  amount,
  retailCost = null,
  deductible = 0,
  coverageLevel1 = null
) => {
  // Default object in case we return early
  const defaultResult = {
    costDisplay: 'N/A',
    explanation: '',
    isDeductibleStage: false
  };

  // If amount is missing => show 'N/A' unless it's costType=3 with no retail cost
  if (amount === undefined || amount === null || amount === 'N/A') {
    // If costType=3 but we have no valid retailCost => treat as 100% patient responsibility
    if (costType === 3) {
      return {
        costDisplay: '100%',
        explanation: 'No retail cost data found. Patient pays 100% at deductible stage until meet dedutible.',
        isDeductibleStage: true
      };
    }
    return defaultResult;
  }

  // ----------------------------------
  // costType=3 => Deductible Logic
  // ----------------------------------
  if (costType === 3) {
    // If no valid retail cost => treat as 100%
    if (typeof retailCost !== 'number') {
      return {
        costDisplay: '100%',
        explanation: 'No retail cost data found. Patient pays 100% at deductible stage until meet dedutible.',
        isDeductibleStage: true
      };
    }

    // This signals we are in the deductible stage
    let isDeductibleStage = true;

    // 1) If retail cost <= deductible => pay 100% of retail cost
    if (retailCost <= deductible) {
      return {
        costDisplay: `$${retailCost.toFixed(2)}`,
        explanation: `Retail cost ($${retailCost.toFixed(
          2
        )}) <= deductible ($${deductible.toFixed(2)}). Patient pays full cost.`,
        isDeductibleStage
      };
    }

    // 2) If retail cost > deductible => difference = retail - deductible
    const costDifference = retailCost - deductible;
    if (!coverageLevel1) {
      // Fallback if coverage-level-1 missing
      return {
        costDisplay: `$${retailCost.toFixed(2)}`,
        explanation: `No coverage-level-1 info; default to full retail cost.`,
        isDeductibleStage
      };
    }

    let finalCost = 0;
    let explanation = '';
    const cov1Type = parseInt(coverageLevel1.cost_type_pref, 10);
    const cov1Amount = parseFloat(coverageLevel1.cost_amt_pref);

    // coverage-level-1 is a percentage
    if (cov1Type === 2) {
      finalCost = deductible + costDifference * cov1Amount;
      explanation = `Retail cost = $${retailCost.toFixed(
        2
      )} > deductible = $${deductible.toFixed(
        2
      )}. Pay $${deductible.toFixed(
        2
      )} + ${(cov1Amount * 100).toFixed(
        0
      )}% of $${costDifference.toFixed(2)} = $${finalCost.toFixed(2)}.`;
    }
    // coverage-level-1 is a fixed copay
    else if (cov1Type === 1) {
      finalCost = deductible + cov1Amount;
      explanation = `Retail cost = $${retailCost.toFixed(
        2
      )} > deductible = $${deductible.toFixed(
        2
      )}. Pay $${deductible.toFixed(2)} + $${cov1Amount.toFixed(
        2
      )} = $${finalCost.toFixed(2)}.`;
    } else {
      // Fallback if not recognized
      finalCost = retailCost;
      explanation = `Could not determine coverage-level-1 type; default full cost = $${retailCost.toFixed(2)}.`;
    }

    return {
      costDisplay: `$${finalCost.toFixed(2)}`,
      explanation,
      isDeductibleStage
    };
  }

  // ----------------------------------
  // Other cost types
  // ----------------------------------
  if (costType === 0) {
    return {
      costDisplay: `$${parseFloat(amount).toFixed(2)}`,
      explanation: '',
      isDeductibleStage: false
    };
  }

  if (costType === 2) {
    const percentage = parseFloat(amount);
    let display = `${(percentage * 100).toFixed(0)}%`;
    let explanation = '';
    if (retailCost) {
      const costVal = (retailCost * percentage).toFixed(2);
      display += ` ($${costVal})`;
      explanation = `Pay ${(percentage * 100).toFixed(0)}% of $${retailCost.toFixed(2)}.`;
    }
    return {
      costDisplay: display,
      explanation,
      isDeductibleStage: false
    };
  }

  if (costType === 1) {
    return {
      costDisplay: `$${parseFloat(amount).toFixed(2)}`,
      explanation: '',
      isDeductibleStage: false
    };
  }

  // Fallback if unknown
  return {
    costDisplay: amount,
    explanation: '',
    isDeductibleStage: false
  };
};


export const SupplyDurationSelector = ({ value, onChange }) => {
  return (
    <Select value={value} style={{ width: 120 }} onChange={onChange}>
      <Select.Option value="30days">30 Days</Select.Option>
      <Select.Option value="90days">90 Days</Select.Option>
    </Select>
  );
};

export const PharmacyTypeSelector = ({ value, onChange }) => {
  return (
    <Select value={value} style={{ width: 160 }} onChange={onChange}>
      <Select.Option value="retail">Retail Pharmacy</Select.Option>
      <Select.Option value="mail">Mail Pharmacy</Select.Option>
    </Select>
  );
};

const processAndDisplayInsulinCostNew = (insulinCostData, daysSupplyFilter, pharmacyType) => {
  if (!Array.isArray(insulinCostData)) {
    return 'No insulin cost data available'; // Placeholder for no data
  }

  const daysSupplyValue = daysSupplyFilter === '30days' ? 1 : (daysSupplyFilter === '90days' ? 2 : 1);
  const coverageLevels = {
    0: '0. Deductible',
    1: '1. Initial Coverage',
    // 2: '2. Coverage Gap',
    3: '2. Catastrophic',
  };

  let coverageInfo = [
    { level: '0', preferredCost: 'N/A', nonPreferredCost: 'N/A' },
    { level: '1', preferredCost: 'N/A', nonPreferredCost: 'N/A' }, // Assume 'N/A' by default
    // { level: '2', preferredCost: 'N/A', nonPreferredCost: 'N/A' }, // Assume 'N/A' by default
    { level: '3', preferredCost: 0, nonPreferredCost: 0 }, // Catastrophic coverage with $0 cost
  ];

  insulinCostData.forEach(cost => {
    if (cost.days_supply === daysSupplyValue) {
      let preferredCost, nonPreferredCost;
      if (pharmacyType === 'retail') {
        preferredCost = cost.copay_amt_pref_insln !== null ? cost.copay_amt_pref_insln : cost.copay_amt_nonpref_insln;
        nonPreferredCost = cost.copay_amt_nonpref_insln;
      } else { // Handling for mail pharmacy
        preferredCost = cost.copay_amt_mail_pref_insln !== null ? cost.copay_amt_mail_pref_insln : cost.copay_amt_mail_nonpref_insln;
        nonPreferredCost = cost.copay_amt_mail_nonpref_insln;
      }

      preferredCost = (preferredCost !== null && preferredCost !== undefined) ? preferredCost.toString() : 'N/A';
      nonPreferredCost = (nonPreferredCost !== null && nonPreferredCost !== undefined) ? nonPreferredCost.toString() : 'N/A';
      
      coverageInfo[0].preferredCost = preferredCost;
      coverageInfo[0].nonPreferredCost = nonPreferredCost;
      coverageInfo[1].preferredCost = preferredCost;
      coverageInfo[1].nonPreferredCost = nonPreferredCost;
      // coverageInfo[2].preferredCost = preferredCost; // Assuming coverage gap has the same cost
      // coverageInfo[2].nonPreferredCost = nonPreferredCost;
    }
  });

  // Adjust level 3 costs based on levels 1 and 2
  if (coverageInfo[1].preferredCost === 'N/A' && coverageInfo[2].preferredCost === 'N/A') {
    coverageInfo[3].preferredCost = 'N/A';
  }
  if (coverageInfo[1].nonPreferredCost === 'N/A' && coverageInfo[2].nonPreferredCost === 'N/A') {
    coverageInfo[3].nonPreferredCost = 'N/A';
  }

  // Map the coverageInfo to table rows, excluding non-preferred pharmacy costs
  const tableRows = coverageInfo.map((info, index) => {
    const coverageWord = coverageLevels[info.level];
    const formattedPreferredCost = info.preferredCost !== 'N/A' ? `$${parseFloat(info.preferredCost).toFixed(2)}` : 'N/A';
    
    return (
      <tr key={index}>
        <td>{coverageWord}</td>
        <td>{formattedPreferredCost}</td>
      </tr>
    );
  });

  return (
    <table>
      <thead>
        <tr>
          <th>Coverage Level</th>
          <th></th>
        </tr>
      </thead>
      <tbody>{tableRows}</tbody>
    </table>
  );
};

function normalizeDrugName(name) {
  if (!name) return "";
  // Convert to lowercase.
  let normalized = name.toLowerCase();
  // Remove digits.
  normalized = normalized.replace(/\d+/g, "");
  // Remove dosage units (mg, ml, etc.)
  normalized = normalized.replace(/\b(mg|ml|mcg|g)\b/g, "");
  // Remove common stopwords (tablet, oral, capsule, injection, etc.)
  normalized = normalized.replace(/\b(tablet|oral|capsule|injection|suspension|solution|strip|kit)\b/g, "");
  // Remove punctuation and extra whitespace.
  // eslint-disable-next-line
  normalized = normalized.replace(/[\/\-\[\]\(\),]/g, " ");
  normalized = normalized.replace(/\s+/g, " ").trim();
  return normalized;
}

export const getCoverageLevelWord = (level) => {
  const coverageLevels = {
    0: '0. Deductible',
    1: '1. Initial Coverage',
    // 2: '2. Coverage Gap',
    3: '2. Catastrophic',
  };
  return coverageLevels[level] || 'Unknown';
};
const patientOutOfPocketCostRender = (record, selectedDaysSupply, selectedPharmacyType, deductible, drugCosts) => {
  // console.log('Deductible:', deductible);

  if (!Array.isArray(record.out_of_pocket_cost)) {
    return '';
  }

  const daysSupplyValue = selectedDaysSupply === '30days' ? 1 : 2;
  const isInsulin = record.out_of_pocket_cost.some(cost => cost.coverage_level === undefined);
  

  const mailOrderNotOffered = record.out_of_pocket_cost.some(cost =>
    cost.days_supply === daysSupplyValue &&
    cost.cost_amt_mail_pref === 'not offered' &&
    cost.cost_amt_mail_nonpref === 'not offered'
  );

  // If the mail order benefit is not offered, display the message and do not render the rest of the table
  if (mailOrderNotOffered && selectedPharmacyType === 'mail') {
    return <p>The plan doesn’t offer a mail order benefit.</p>;
  }

  if (deductible > 0 && !record.out_of_pocket_cost.some(cost => cost.coverage_level === 0 && cost.days_supply === daysSupplyValue)) {
    const deductibleStageEntry = {
      coverage_level: 0,
      days_supply: daysSupplyValue,
      cost_type_pref: 3, // This could be a special indicator for deductible stage.
      cost_type_nonpref: 3, // Assuming the same applies for non-preferred.
      cost_type_mail_pref: 3, // Assuming the same applies for mail preferred.
      cost_type_mail_nonpref: 3, // Assuming the same applies for mail non-preferred.
      cost_amt_pref: '1.00', // 100% cost to patient during the deductible stage.
      cost_amt_nonpref: '1.00', // Assuming 100% for non-preferred as well.
      cost_amt_mail_pref: '1.00', // Assuming 100% for mail order preferred.
      cost_amt_mail_nonpref: '1.00' // Assuming 100% for mail order non-preferred.
    };
    record.out_of_pocket_cost.unshift(deductibleStageEntry);
  }

  // If the drug is insulin, process using insulin-specific logic
  if (isInsulin) {
    return processAndDisplayInsulinCostNew(record.out_of_pocket_cost, selectedDaysSupply, selectedPharmacyType);
  }

  const retailCostMap = {};

  if (Array.isArray(drugCosts)) {
    console.log("Processing drugCosts for record:", record.nonproprietaryname);

    drugCosts.forEach(cost => {
      // Normalize the cost's drug_name
      const normalizedCostKey = normalizeDrugName(cost.drug_name);
      if (!retailCostMap[normalizedCostKey]) {
        retailCostMap[normalizedCostKey] = { retail: null, mail: null };
      }
      if (cost.pharmacy_name.toLowerCase().includes("mail")) {
        retailCostMap[normalizedCostKey].mail = parseFloat(cost.retail_cost || 0);
      } else {
        retailCostMap[normalizedCostKey].retail = parseFloat(cost.retail_cost || 0);
      }
    });
    console.log("Completed retailCostMap with pharmacy classification:", retailCostMap);
  } else {
    console.log("No drug_costs array found in record:", drugCosts);
  }

  let nonproprietaryKey = '';
  if (record.nonproprietaryname) {
    // Remove "(generic)" if present (case-insensitive), then lowercase and trim.
    nonproprietaryKey = record.nonproprietaryname.replace(/\(generic\)/i, "").toLowerCase().trim();
  } else if (record.proprietaryname) {
    nonproprietaryKey = record.proprietaryname.split(" ")[0].toLowerCase();
  }

  const normalizedRecordKey = normalizeDrugName(nonproprietaryKey);

  let matchingCosts;
  if (retailCostMap.hasOwnProperty(normalizedRecordKey)) {
    matchingCosts = [normalizedRecordKey, retailCostMap[normalizedRecordKey]];
  } else {
    // Fallback: find a key that includes our normalizedRecordKey.
    matchingCosts = Object.entries(retailCostMap).find(([drugNameKey]) =>
      drugNameKey.includes(normalizedRecordKey)
    );
  }
  

  // Default to empty object if no match is found
  const retailCosts = matchingCosts ? matchingCosts[1] : { retail: 0, mail: 0 };

  console.log("Retail costs for nonproprietaryname with classification:", {
    nonproprietaryKey,
    retailCosts,
  });

  let selectedPharmacyCost = 0;

  // Adjust retail cost based on days supply and pharmacy type
  if (selectedPharmacyType === "mail") {
    // Mail order cost is for 90 days; adjust if 30 days is selected
    selectedPharmacyCost = selectedDaysSupply === '30days' 
      ? (retailCosts.mail !== null && retailCosts.mail !== 0 ? retailCosts.mail / 3 : null) 
      : (retailCosts.mail !== null && retailCosts.mail !== 0 ? retailCosts.mail : null);
  } else {
    // Retail cost is for 30 days; adjust if 90 days is selected
    selectedPharmacyCost = selectedDaysSupply === '30days' 
      ? (retailCosts.retail !== null && retailCosts.retail !== 0 ? retailCosts.retail : null) 
      : (retailCosts.retail !== null && retailCosts.retail !== 0 ? retailCosts.retail * 3 : null);
  }

  // Handle case where cost data is missing
  let selectedPharmacyCostDisplay; // Separate display value
  if (selectedPharmacyCost === null || selectedPharmacyCost === undefined) {
    selectedPharmacyCostDisplay = "N/A";
  } else {
    selectedPharmacyCostDisplay = `$${selectedPharmacyCost.toFixed(2)}`;
  }

  console.log("Adjusted pharmacy cost based on supply and type:", {
    selectedPharmacyType,
    selectedDaysSupply,
    selectedPharmacyCost,
    selectedPharmacyCostDisplay,
  });

  const coverageLevel1Entry = record.out_of_pocket_cost.find(
    (c) => c.coverage_level === 1 && c.days_supply === daysSupplyValue
  );

    // Filter costs based on days supply and coverage level, assuming the array might not be sorted by coverage level
    const sortedAndFilteredCosts = record.out_of_pocket_cost
      .filter(cost => cost.days_supply === daysSupplyValue && cost.coverage_level !== 2)
      .sort((a, b) => a.coverage_level - b.coverage_level);

    console.log('Sorted and filtered costs:', sortedAndFilteredCosts);

    // If there's no data after filtering, return a message or a default UI element
    if (sortedAndFilteredCosts.length === 0) {
      return 'No cost data available for the selected options';
    }

    // Generate table rows for each cost
    const tableRows = sortedAndFilteredCosts.map((cost, index) => {
      const coverageWord = getCoverageLevelWord(cost.coverage_level);

      // Only display retail cost for the first row
      const retailCostDisplay = index === 0 ? selectedPharmacyCostDisplay : '';

      let pharmacyKey;
      if (cost.coverage_level === 0) {
        pharmacyKey = 'cost_amt_pref'; // Deductible stage always uses preferred cost
      } else {
        pharmacyKey = selectedPharmacyType === 'retail' ? 'cost_amt_pref' : 'cost_amt_mail_pref';
      }
      const mailOrderNotOffered = (pharmacyKey.includes('mail') && 
                                      cost.cost_amt_mail_pref === 'not offered' &&
                                      cost.cost_amt_mail_nonpref === 'not offered');

      let costCell;
      // 1) If coverage_level=3 => Catastrophic => $0.00 always
      if (cost.coverage_level === 3) {
        costCell = '$0.00';
      }
      // 2) If mail order not offered
      else if (mailOrderNotOffered) {
        costCell = 'The plan doesn’t offer a mail order benefit';
      }
      // 3) Otherwise, use `formatCost`
      else {
        const { costDisplay, explanation, isDeductibleStage } = formatCost(
          cost.cost_type_pref,
          cost[pharmacyKey],
          typeof selectedPharmacyCost === 'number' ? selectedPharmacyCost : null,
          deductible,
          coverageLevel1Entry
        );

        // If costType=3 => show question icon + tooltip
        if (isDeductibleStage) {
          costCell = (
            <Tooltip placement="bottom" title={explanation}>
              <span style={{ cursor: 'pointer' }}>
                {costDisplay} <QuestionCircleOutlined style={{ marginLeft: 4 }} />
              </span>
            </Tooltip>
          );
        } else {
          costCell = costDisplay;
        }
      }

      // if (index === 0) {
      // // console.log('First cost entry:', cost);
      // // console.log('First row display:', `${coverageWord}: ${costFormatted}`);
      // }

      return (
      <tr key={index}>
      <td>{coverageWord}</td>
      <td>{costCell}</td>
      <td>{retailCostDisplay}</td> 
      </tr>
      );
      });

      return (
        <table>
        <thead>
        <tr>
        <th>Coverage Level</th>
        <th>{/*costHeader */}</th> {/* Updated header */}
        <th>Retail Cost</th>
        </tr>
        </thead>
        <tbody>{tableRows}</tbody>
        </table>
      );
};

const tierLevelDescriptions = {
  1: 'Preferred Generic Drug',
  2: 'Generic Drug',
  3: 'Preferred Brand Drug',
  4: 'Non-Preferred Drug',
  5: 'Specialty Tier Drug',
  6: 'Select Care Drug',
};

const tierTooltipContent = (
  <div>
    <p>Tier 1: {tierLevelDescriptions[1]}</p>
    <p>Tier 2: {tierLevelDescriptions[2]}</p>
    <p>Tier 3: {tierLevelDescriptions[3]}</p>
    <p>Tier 4: {tierLevelDescriptions[4]}</p>
    <p>Tier 5: {tierLevelDescriptions[5]}</p>
    <p>Tier 6: {tierLevelDescriptions[6]}</p>
  </div>
);

const resultTableColumns = (selectedDaysSupply, selectedPharmacyType, handlePopupOpen, selectedInsurancePlan, selectedPlanDetails, drugCosts) => {
  console.log('Selected Plan Details:', selectedPlanDetails);
  console.log('Deductible value being passed:', selectedPlanDetails ? selectedPlanDetails.deductible : 0);
  return [
    {
      dataIndex: 'proprietaryname',
      title: 'Drug Name',
      render(value, record) {
        const displayValue = record.nonproprietaryname 
          ? (<span>
                <strong>{record.nonproprietaryname}</strong> 
                <br />
                {record.proprietaryname}
             </span>)
          : record.proprietaryname;
        return (
          <div>
            {displayValue}
          </div>
        );
      },
    },
    {
      dataIndex: 'tier_level_value',
      title: (
        <Tooltip placement="bottom" title={tierTooltipContent}>
          Tier Level Value <QuestionCircleOutlined />
        </Tooltip>
      ),
      render: (tierLevelValue, record) => {
        // Return only 'Not Covered' if the tier level is not available, without 'Unknown Tier'
        if (tierLevelValue === 'Not Covered') return 'Not Covered';
        
        const description = tierLevelDescriptions[tierLevelValue];
        const deductible = selectedPlanDetails?.deductible || 0; // Safely access deductible or default to 0

        // Ensure out_of_pocket_cost is an array before calling .some()
        const outOfPocketCostArray = Array.isArray(record.out_of_pocket_cost) ? record.out_of_pocket_cost : [];
        const hasDeductibleExclusion = outOfPocketCostArray.some(cost => cost.ded_applies_yn === 'N');

        // Only show the message if there is a deductible
        const deductibleMessage =
          deductible > 0 && hasDeductibleExclusion ? (
            <div style={{ fontSize: 'smaller', color: '#888', marginTop: '4px' }}>
              (Deductible does not apply to this tier)
            </div>
          ) : null;
        return (
          <div>
            {description ? `${tierLevelValue} (${description})` : tierLevelValue}
            {deductibleMessage}
          </div>
        );
      },
    },
    {
      dataIndex: 'out_of_pocket_cost',
      title: (
        <div>
          Patient Out-of-pocket ($)
          <div style={{ fontSize: 'smaller', color: '#888', lineHeight: 1.5 }}>
            <div>(All costs are estimates for reference)</div>
            <div>(% is the percentage of the drug retail price)</div>
          </div>
        </div>
      ),
      render: (_, record) => patientOutOfPocketCostRender(
        record, 
        selectedDaysSupply, 
        selectedPharmacyType, 
        selectedPlanDetails ? selectedPlanDetails.deductible : 0,
        drugCosts
      ),
    },
    {
      dataIndex: 'active_ingred_unit',
      title: 'Coverage Requirements',
      render: (_, drug) => {
        return drug.covered ? renderRequirements(drug, selectedInsurancePlan, handlePopupOpen) : '';
      },
    },
  ];
};


function getCustomTierSortOrder(hasTierSix) {
  return hasTierSix ? [1, 2, 6, 3, 4, 5, 'Not Covered'] : [1, 2, 3, 4, 5, 6, 'Not Covered'];
  }

function sortByTier(groups, sortOrder) {
  return groups.sort((a, b) => {
    let tierA = a.tier_level_value === 'Not Covered' ? 'Not Covered' : parseInt(a.tier_level_value, 10);
    let tierB = b.tier_level_value === 'Not Covered' ? 'Not Covered' : parseInt(b.tier_level_value, 10);

    let indexA = sortOrder.indexOf(tierA);
    let indexB = sortOrder.indexOf(tierB);

    if (indexA === -1) indexA = sortOrder.length;
    if (indexB === -1) indexB = sortOrder.length;

    return indexA - indexB;
  });
}

export default function DrugClassResultsTable({ results, selectedInsurancePlan, selectedPlanDetails, expandedRow, setExpandedRow, drugCosts }) {
  useEffect(() => {
    console.log("Updated results in DrugClassResultsTable:", results);
  }, [results]);

  
  // const toggleDetails = (nameKey) => {
  //   setExpandedRow(expandedRow === nameKey ? null : nameKey);
  // };

  const [tierDetailsModalVisible, setTierDetailsModalVisible] = useState(false);
  // const [tierDetails, setTierDetails] = useState(null);
  const [supplyDuration, setSupplyDuration] = useState('30days'); // Default value
  const [pharmacyType, setPharmacyType] = useState('retail'); // Default value
  const [selectedDrugName, setSelectedDrugName] = useState('');
  const [selectedDaysSupply, setSelectedDaysSupply] = useState('30days'); 
  const [selectedPharmacyType, setSelectedPharmacyType] = useState('retail'); 
  const [visiblePopup, setVisiblePopup] = useState(false);
  const [popupContent, setPopupContent] = useState('');
  const [popupTitle, setPopupTitle] = useState('');
  const hasTierSix = results.some(drug => drug.tier_level_value === 6);
  const sortOrder = getCustomTierSortOrder(hasTierSix);
  const sortedGroupedDrugResults = sortByTier(getFirstRowByProprietaryName(results), sortOrder);
  
  const handlePopupClose = () => {
    setVisiblePopup(false);

    if (popupTitle.includes('Working-in-progress')) {
      // The popup was for the prior authorization check, so send the email
      sendEmailNotification(selectedDrugName);
    }
    if (popupContent && selectedDrugName) {
      sendEmailNotification({
        subject: 'Requirement Details Accessed',
        body: `The details for ${selectedDrugName} were accessed: ${popupContent}`
      });
    }
  };
  
  const handlePopupOpen = (drugName, content) => {
    setSelectedDrugName(drugName);
    setPopupContent(content);
    setPopupTitle(`Requirement Details for ${drugName}`);
    setVisiblePopup(true);
  };

  // const handlePatientAssistancePopup = (drugName) => {

  //   const drug = drugsWithPatientAssistance.find(d => d.name === drugName.toLowerCase());
  //   if (drug) {
  //     console.log(`Found drug details: ${drug.url}`); // Debugging log
  //     const linkHtml = `Working-in-progress. You can access the patient assistance program <a href="${drug.url}" target="_blank" rel="noopener noreferrer">here</a>.`;
  //     setPopupContent(linkHtml);
  //     setPopupTitle(`Patient Assistance Details for ${drugName}`);
  //     setVisiblePopup(true);
  //   }else {
  //     console.log('Drug details not found.'); // Debugging log
  //   }
  // };


  const handleSupplyDurationChange = (newValue) => {
    setSelectedDaysSupply(newValue);
    // additional logic to refresh the data if necessary
  };
  
  const handlePharmacyTypeChange = (newValue) => {
    setSelectedPharmacyType(newValue);
    // additional logic to refresh the data if necessary
  };

  
  return (
  <div>
    <SupplyDurationSelector value={selectedDaysSupply} onChange={handleSupplyDurationChange} />
    <PharmacyTypeSelector value={selectedPharmacyType} onChange={handlePharmacyTypeChange} />

    <Modal
        title={popupTitle} // Use the state variable for the title
        visible={visiblePopup}
        onOk={() => {
          handlePopupClose();
          // If you want to send an email when OK is clicked, you can call sendEmailNotification here.
          sendEmailNotification({
            subject: 'Patient Assistance Program Accessed',
            body: `The patient assistance program for ${selectedDrugName} was accessed.`
          });
        }}
        onCancel={handlePopupClose} // Optionally keep the cancel button behavior the same as OK
        footer={[
          <Button key="submit" type="primary" onClick={handlePopupClose}>
            OK
          </Button>,
        ]}
      >
        <p dangerouslySetInnerHTML={{ __html: popupContent }}></p>
      </Modal>
    <Table
      columns={resultTableColumns(selectedDaysSupply, selectedPharmacyType, handlePopupOpen, selectedInsurancePlan, selectedPlanDetails, drugCosts)}
      dataSource={sortedGroupedDrugResults}
      rowKey="proprietaryname" 
      pagination={false} 
    />
    <Modal
      title="Beneficiary Cost Details"
      visible={tierDetailsModalVisible}
      onOk={() => setTierDetailsModalVisible(false)}
      onCancel={() => setTierDetailsModalVisible(false)}
      footer={null}
    >
      {/* Display Deductible information at the top */}
      {selectedPlanDetails.deductible !== null && selectedPlanDetails.deductible !== undefined && (
        <div>
          <strong>Annual Deductible:</strong> ${selectedPlanDetails.deductible.toFixed(2)}
        </div>
      )}
      {/* Supply Duration and Pharmacy Type Selectors within the Modal */}
      <SupplyDurationSelector value={supplyDuration} onChange={setSupplyDuration} />
      <PharmacyTypeSelector value={pharmacyType} onChange={setPharmacyType} />
    </Modal>
    </div>
  );
};