import { Form, Input, Dropdown, Button } from 'antd';
import { CloseCircleOutlined } from '@ant-design/icons';
import { useEffect, useState } from "react";

const FOCUS_STATUS = {
  BLUR: 0,
  WILL_BLUR: 1,
  WILL_FOCUS: 2,
  FOCUS: 3,
};

export default function StatusSelect(props) {
  const [searchText, setSearchText] = useState(props.defaultValue || "");
  const [displayValue, setDisplayValue] = useState(""); // For displaying the label in the input
  const [{ status, help }, setStatus] = useState({ help: "", status: "" });
  const [openDropdown, setOpenDropdown] = useState(false);
  const [focus, setFocus] = useState(FOCUS_STATUS.BLUR);

  useEffect(() => {
    let canceled = false;
    const { length } = searchText;
    if (length === 8 || length === 11) {
      const promise = props.onSearch(searchText);
      if (promise) {
        setStatus({
          help: "Searching plan...",
          status: "validating",
        });
        
        promise
        .then((response) => {
          if (!canceled) {
            if (response?.error_code) {
              // Handle backend-defined errors
              switch (response.error_code) {

                case "INVALID_MEDICARE_NUMBER":
                  setStatus({
                    help: (
                      <span>
                        {response.message} <br />
                        If you need further assistance,{" "}
                        <span
                          style={{ color: "blue", textDecoration: "underline", cursor: "pointer" }}
                          onClick={props.showContactModal}
                        >
                          leave your email here
                        </span>
                        , and we will get back to you in 24 hours.
                      </span>
                    ),
                    status: "error",
                  });
                  break;

                case "INVALID_PLAN_FORMAT":
                    setStatus({
                      help: (
                        <span>
                          {response.message} <br />
                          If you need further assistance,{" "}
                          <span
                            style={{ color: "blue", textDecoration: "underline", cursor: "pointer" }}
                            onClick={props.showContactModal}
                          >
                            leave your email here
                          </span>
                          , and we will get back to you in 24 hours.
                        </span>
                      ),
                      status: "error",
                    });
                    break;
                  
                case "INVALID_FORMAT":
                    setStatus({
                      help: (
                        <span>
                          {response.message} <br />
                          If you need further assistance,{" "}
                          <span
                            style={{ color: "blue", textDecoration: "underline", cursor: "pointer" }}
                            onClick={props.showContactModal}
                          >
                            leave your email here
                          </span>
                          , and we will get back to you in 24 hours.
                        </span>
                      ),
                      status: "error",
                    });
                    break;
                  
                case 'UPDATED_PLAN':
                    setStatus({
                      help: (
                        <span>
                          {response.message} <br />
                          If you need further assistance,{" "}
                          <span
                            style={{ color: "blue", textDecoration: "underline", cursor: "pointer" }}
                            onClick={props.showContactModal}
                          >
                            leave your email here
                          </span>
                          , and we will get back to you in 24 hours.
                        </span>
                      ),
                      status: "error",
                    });
                    break;
                  
                case 'RELATED_PLANS_FOUND':
                    setStatus({
                      help: (
                        <span>
                          {response.message} <br />
                          If you need further assistance,{" "}
                          <span
                            style={{ color: "blue", textDecoration: "underline", cursor: "pointer" }}
                            onClick={props.showContactModal}
                          >
                            leave your email here
                          </span>
                          , and we will get back to you in 24 hours.
                        </span>
                      ),
                      status: "error",
                    });
                    break;
                  
                case 'DISCONTINUED_PLAN_NO_MATCH':
                  setStatus({
                    help: (
                      <span>
                        {response.message} <br />
                        If you need further assistance,{" "}
                        <span
                          style={{ color: "blue", textDecoration: "underline", cursor: "pointer" }}
                          onClick={props.showContactModal}
                        >
                          leave your email here
                        </span>
                        , and we will get back to you in 24 hours.
                      </span>
                    ),
                    status: "error",
                  });
                  break;

                  case 'RETIREE_PLAN_FOUND':
                    setStatus({
                      help: (
                        <span>
                          {/* Render the HTML message using dangerouslySetInnerHTML so the hyperlink is clickable */}
                          <span dangerouslySetInnerHTML={{ __html: response.message }}></span>
                        </span>
                      ),
                      status: "error"
                    });
                    break;

                case 'RETIREE_PLAN':
                  setStatus({
                    help: (
                      <span>
                        {response.message} <br />
                        Please{" "}
                        <span
                          style={{ color: "blue", textDecoration: "underline", cursor: "pointer" }}
                          onClick={props.showContactModal}
                        >
                          leave your email here
                        </span>
                        , and we will notify you when it is available.
                      </span>
                    ),
                    status: "error",
                  });
                  break;
                
                case 'PLAN_NOT_FOUND':
                  setStatus({
                    help: (
                      <span>
                        {response.message} <br />
                        Please{" "}
                        <span
                          style={{ color: "blue", textDecoration: "underline", cursor: "pointer" }}
                          onClick={props.showContactModal}
                        >
                          leave your email here
                        </span>
                        , and we will notify you when it is available.
                      </span>
                    ),
                    status: "error",
                  });
                  break;
                default:
                  setStatus({
                    help: "An unknown error occurred. Please try again later.",
                    status: "error",
                  });
              }
            } else {
              // Treat as success if no error code is present
              setOpenDropdown(true);
              setStatus({
                help: "",
                status: "success",
              });
            }
          }
        })
        .catch((error) => {
          if (!canceled) {
            // Handle network or unexpected errors
            const errorMessage =
              error.response?.data?.message || "An unexpected error occurred. Please try again later.";
            setStatus({
              help: errorMessage,
              status: "error",
            });
          }
        });
    }
    } else if (length === 0) {
      setStatus({
        help: (
          <span>
            Please input the Medicare Plan ID, which starts with 'H' or 'S' and is followed by 7 or 10 digits, often found on the bottom-right corner of Medicare card <br />
            (e.g., H0543-193 or H0543-193-000, Input with or without '-'.) <br /> 
            </span>
        ),
        status: "null",
      });
      setOpenDropdown(false);
    } else if (length > 0 && length < 8) {
      setStatus({
        help: (
          <span>
            Please continue input, which starts with 'H' or 'S' and is followed by 7 or 10 digits, often found on the bottom-right corner of Medicare card <br />
            (e.g., H0543-193 or H0543-193-000, Input with or without '-'.) <br /> 
          </span>
        ),
        status: "null",
      });
      setOpenDropdown(false);
    } else if (length > 11) {
      setStatus({
        help: (
          <span>
            Error: The Plan ID should be exactly 8 or 11 characters long. <br />
            (Plan IDs are often found on the bottom-right corner of Medicare cards, starting with 'H/S/R', sometimes labeled as 'CMS #')
          </span>
        ),
        status: "error",
      });
      setOpenDropdown(false);

    } else if (length > 8 && length < 11) {
      setStatus({
        help: (
          <span>
            The Plan ID code should be exactly 8 or 11 characters long. <br />
            (Plan IDs are often found on the bottom-right corner of Medicare cards, starting with 'H/S/R', sometimes labeled as 'CMS #')
          </span>
        ),
        status: "warning",
      });
      setOpenDropdown(false);
    } else {
      setStatus({
        help: "",
        status: "",
      });
    }
    setOpenDropdown(false);
    return () => {
      canceled = true;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchText]);

  function handleOptionSelect({ key }) {
    const selectedOption = props.options.find(
      (option) => String(option.value) === String(key)
    );
    if (selectedOption) {
      setOpenDropdown(false);
      setDisplayValue(selectedOption.label); // Update the input to show the label
      // If needed, pass this value to a parent component or use it for further logic here
      props.onChange(selectedOption.value); // Assuming you have a prop function to handle this
    }
  }

  function handleInputChange(e) {
    const input = e.target.value.toUpperCase();
    setSearchText(input.replace(/-/g, ''));
    setDisplayValue(input); // Allow users to type and search, adjusting display
  }


  useEffect(() => {
    let timer;
    if (focus === FOCUS_STATUS.WILL_FOCUS) {
      setFocus(FOCUS_STATUS.FOCUS)
    }
    else if (focus === FOCUS_STATUS.WILL_BLUR) {
      timer = setTimeout(() => {
        if (focus === FOCUS_STATUS.WILL_BLUR) {
          setFocus(FOCUS_STATUS.BLUR)
        }
      }, 400);
    }
    return () => {
      clearTimeout(timer);
    }
  }, [focus]);

  useEffect(() => {
    if (props.options.length === 1 && props.autoFill){
      handleOptionSelect({key:props.options[0].value})
    }
     // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.options, props.autoFill]);

  return (
    <Form.Item hasFeedback validateStatus={status} help={help}>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <Dropdown
          dropdownRender={(menus) =>
            props.options.length && status === "success" ? menus : <i hidden />
          }
          placement="bottom"
          open={openDropdown && focus}
          trigger={['click']}  // Ensure dropdown expands on click
          menu={{
            onClick: handleOptionSelect,
            items: props.options.map((option) => {
              return {
                label: option.label,
                key: option.value,
              };
            }),
          }}
        >
          <Input
            value={displayValue}
            onChange={handleInputChange}
            onFocus={() => setFocus(FOCUS_STATUS.WILL_FOCUS)}
            onBlur={() => setFocus(FOCUS_STATUS.WILL_BLUR)}
            style={{ paddingRight: '30px' }}  // Make room for clear button inside input
          />
        </Dropdown>
        {displayValue && (
          <Button
            icon={<CloseCircleOutlined />}
            onClick={() => {  // Clear functionality
              setDisplayValue('');
              setSearchText('');
              setStatus({ help: "", status: "" });  // Reset status
            }}
            style={{
              border: 'none',
              background: 'transparent',
              color: '#d9d9d9',
              marginLeft: '8px',  // Position the clear button inside the input field on the right
              cursor: 'pointer',
              zIndex: 10  // Ensure the button is clickable over the dropdown
            }}
          />
        )}
      </div>
    </Form.Item>
  );
}
